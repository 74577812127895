import React from "react";

// Customizable Area Start
import { Box, Typography, IconButton, TextField, Button, LinearProgress, CircularProgress,Dialog } from "@material-ui/core";
import {
  createTheme,
  styled,
  Theme,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  attachFileIcon,
  backIcon,
  closeIcon,
  closeImageIcon,
  downLoadIcon,
  messageIcon,
  purpleCloseIcon,
  sendIcon,
  uploadDocumentIcon,
  UserIcon,
  closemodal
} from "./assets";
import CustomViewImage from "../../../components/src/ChatViewImageVideo.web";
import CustomDialog from "../../../components/src/CustomDialog.web";
import Dropzone, { DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';
import {MessageData,LocalMessage} from './ViewChatController';
import moment from "moment";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
    secondary:{
      main:"#6F05EA"
    }
  },
  typography: {
    fontFamily: "Montserrat",
  },
});
interface MessageProps {
  theme: Theme;
  isUser?: boolean;
}
// Customizable Area End

import ViewChatController, { configJSON, IMessage, Props } from "./ViewChatController";

// Customizable Area Start

const MessageContainer = styled(Box)(({ theme, isUser }: MessageProps) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: isUser ? "flex-end" : "flex-start",
  margin: "0.5rem 0",
  "& .message-bubble": {
    maxWidth: "60%",
    padding: "0.5rem 1rem",
    borderRadius: "10px",
    backgroundColor: isUser ? "#EDE1FF" : "#EFEFEF",
  },
  "& .replay-msg-container": {
    backgroundColor: "#fff",
    padding: "10px 11px",
    borderRadius: "10px",
    color: "#B7B7B7",
  },
  "& .message-image": {
    height: "152px",
    width: "152px",
    borderRadius: "4px",
  },
  "& .message-bubble-text": {
    color:'#787878',
    fontSize: "15px",
    fontWeight:500,
    fontFamily:'Montserrat',
    [theme.breakpoints.down("md")]: {
      fontSize: "13px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },
  "& .msg-timestamp-container": {
    display: "flex",
    gap: "10px",
  },
  "& .msg-timestamp": {
    color: "#00000040",
    fontSize: "12px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
    },
  },
  "& .msg-reply-link": {
    color: "#6200ea",
    cursor: "pointer",
    borderBottom: "1px solid #6F05EA",
    fontSize: "12px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
    },
  },
  "& .image-container":{
    height:"280px",
    width:"280px",
    [theme.breakpoints.down("md")]: {
      height:"250px",
      width:"250px"
    },
    [theme.breakpoints.down("sm")]: {
      height:"200px",
      width:"200px"
    },
    [theme.breakpoints.down("xs")]: {
      height:"150px",
      width:"150px"
    },
  },
  "& .video-container":{
    maxHeight:"auto",
    width:"280px",
    height:'280px',
    "& video":{
      borderRadius:"8px",
    },
    [theme.breakpoints.down("md")]: {
      width:"250px"
    },
    [theme.breakpoints.down("sm")]: {
      width:"200px"
    },
    [theme.breakpoints.down("xs")]: {
      width:"220px"
    },
  }
}));
const UserNameHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  height: "91px",
  paddingLeft: "35px",
  borderBottom: "1px solid #00000012",
  "& .header-user-img": {
    width: "48px",
    height: "48px",
    borderRadius:'50%',
    [theme.breakpoints.down("md")]: {
      width: "42px",
      height: "42px",
    },
  },
  "& .header-user-name": {
    fontSize: "24px",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
  },
  "& .back-icon": {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
}));
const MessageTypingContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  height: "66px",
  border: "1px solid #ccc",
  alignItems: "center",
  background: "#fff",
  width:'94%',
  marginLeft:32,
  "& .message-input": {
    width: "100%",
    marginRight: 10,
  },
  "& .message-input > div > div > fieldset": {
    borderColor: "transparent !important",
  },
  "& .attach-file-icon-btn": {
    position: "relative",
    "& :hover": {
      backgroundColor: "transparent",
    },
  },
  [theme.breakpoints.down("md")]: {
    height: "55px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "45px",
  },
}));
const TimeContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& .line": {
    width: "100%",
    borderBottom: "1px solid #00000026",
  },
  "& .msg-day": {
    backgroundColor: "#EFEFEF",
    padding: "11px 26px",
    fontSize: "14px",
    fontWeight: 600,
    borderRadius: "7px",
    margin: "0px 50px",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
      margin: "0px 20px",
      padding: "11px 22px",
    },
  },
}));
const ImageContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gap: "4px",
  gridTemplateColumns: "repeat(2, 135px)",
  justifyContent: "flex-start",
  flexDirection:'row',
  "& .image-item": {
    position: "relative",
    width: "135px",
    height: "135px",
    borderRadius: "8px",
    overflow: "hidden",
  },
  "& .image-container.multi": {
    width: "calc(25% - 4px)",
  },
  "& .image-container.single": {
    width: "280px",
    height: "280px",
  },
  "& .gallery-image": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "8px",
  },
  "& .more-images-overlay": {
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    background: "rgba(0, 0, 0, 0.6)",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "24px",
    borderRadius: "8px",
  },
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "repeat(2, 115px)",
    "& .image-item": {
      width: "100px",
      height: "100px",
    },
  },
}));
const MessageReplyContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#F6F0FF",
  padding: "10px",
  borderRadius: "10px",
  "& .reply-message-box": {
    flexGrow: 1,
  },
  "& .reply-message-text": {
    color: "#666",
    fontSize: "15px",
    [theme.breakpoints.down("md")]: {
      fontSize: "13px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },
  "& .reply-message-image": {
    height: "49px",
    width: "45px",
    borderRadius: "4px",
  },
});
const ReplyWriteContainer = styled(Box)({
  display:"flex", 
  flexDirection:"column", 
  marginTop:"auto"
})
const FileUploadContainer = styled(Box)(({ theme }) => ({
  "& .drop-zone-container":{
    border: '2px dashed #00000059',
    borderRadius: '5px',
    padding: '20px 70px',
    textAlign: 'center' as const,
    cursor: 'pointer', 
    display:"flex", 
    alignItems:"center", 
    backgroundColor:"#F8F8F8",
    [theme.breakpoints.down("xs")]: {
      padding: '20px 60px',
  },
  },
  "& .drop-zone-info":{
    display:"flex",
    justifyContent:"center", 
    alignItems:"center", 
    flexDirection:"column"
  },
  "& .upload-icon":{
    height:"90px", 
    width:"90px",
    [theme.breakpoints.down("sm")]: {
        width: "70px",
        height: "70px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "50px",
      height: "50px",
  },
  },
  "& .drag-drop-text":{
    color:"#787878",
    fontSize:"24px", 
    marginTop:"10px",
    [theme.breakpoints.down("sm")]: {
      fontSize:"20px",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize:"16px",
  },
  },
  "& .support-file":{
    color:"#000000", 
    fontSize:"17px", 
    fontWeight:"500", 
    marginTop:"15px",
    [theme.breakpoints.down("sm")]: {
      fontSize:"14px",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize:"12px",
  },
  },
  "& .upload-progress-container":{
    borderBottom:"1px solid #00000059",
      "& .MuiLinearProgress-colorPrimary":{
      backgroundColor:"#6F05EA"
    }
  },
  "& .upload-progress-file":{
    display:"flex", 
    justifyContent:"space-between",
    alignItems:"center"
  },
  "& .upload-progress-file-name":{
    paddingLeft:"10px",
    fontSize:"12px",
    overflow:"hidden",
    whiteSpace:"nowrap",
    textOverflow:"ellipsis"
  },
  "& .file-list-container":{
    display:"flex", 
    flexDirection:"column", 
    gap:"5px",
    marginTop:"10px",
    width: "100%"
  },
  "& .upload-text":{
    fontSize:"14px",
    fontWeight:"600"
  },
  "& .upload-file-close-icon":{
    height:"14px"
  },
  "& .upload-file-btn-container":{
    display:"flex",
    justifyContent:"center",
    marginTop:"25px"
  },
  "& .upload-file-btn":{
    border: "1px solid #6F05EA",
  borderRadius: "100px",
  padding: "5px 45px",
  fontSize: "18px",
  fontWeight: 700,
  textTransform: "none",
  color: "#6200EA",
  backgroundColor: "transparent",
  boxShadow: "none",
  "&:hover": {
    color: "#6200EA",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "5px 35px",
    fontSize: "14px",
  },
  },
  "& .file-error":{
    color:"#f44336",
    fontSize:"12px",
    textAlign:"center",
  }
}));
const ViewChatContainer = styled(Box)(({ theme }) => ({
  height:"100%",
  "& .leader-container":{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop:20
  }
}));

const StyledCreatedPaymentPopUp = styled(Dialog)({
  "& .MuiDialog-paperWidthMd":{
    maxWidth:"751px"
  },
  "& .caintainerTitle":{
    padding:"33px"
  }
});
const CloseMessageWrapper = styled(Box)({
  display:"flex",
  justifyContent:"flex-end"
});
const CloseIconButton = styled(IconButton)({
  height:"32px", 
  width:"32px"
});
const CreateNonPaymentInfoWrapper = styled(Box)({
  textAlign:'center',
  maxWidth:'547px',
  padding:'15px 70px 60px 70px',
  "@media (max-width: 960px)": {
      padding:'10px 50px 50px 70px'
  },
  "@media (max-width: 600px)": {
      padding:'8px 0px 40px 0px'
  },
  "& .TitleMessage":{
      fontWeight:600,
    fontSize:'24px',
    lineHeight:"34px",
    "@media (max-width: 960px)": {
      fontSize:"20px",
    },
    "@media (max-width: 600px)": {
      fontSize:"16px",
    },
  },
  "& .subTitleTy":{
    lineHeight:"29px",
    fontSize:"20px",
    fontWeight:500,
    marginTop:10,
    "@media (max-width: 960px)": {
      fontSize:"16px",
    },
    "@media (max-width: 600px)": {
      fontSize:"12px",
    },
  }
});
// Customizable Area End

export default class ViewChat extends ViewChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderUserNameWithChat = () => {
    return (
      <UserNameHeader>
        <img
          src={backIcon}
          alt="back-icon"
          onClick={this.props.handleBackListClick}
          className="back-icon"
          data-test-id="view-chat-back-btn"
        />
        <img className="header-user-img" src={this.state.imageUrl} alt="user-icon" />
        <Typography className="header-user-name">
          {this.state.dummyUserName}
        </Typography>
      </UserNameHeader>
    );
  };

  renderReplyMsgImageVideo = () => {
    return this.state.replyMessageData.type === "image" ? (
      <img
        className="message-image"
        src={this.state.replyMessageData.image}
        data-test-id="reply-msg-image"
      />
    ) : (
      <video
        src={this.state.replyMessageData.image}
        className="message-image"
      ></video>
    );
  };
//twilio chat
formatTimeAgo2 = (timestamp: string) => {
  const date = moment(timestamp);
  return date.format('hh:mm A'); // e.g., "08:20 PM"
};
renderMessageList = () => {
  const hasMessages = this.state?.dummyMassageList.length > 0;
  let previousMessageDate: string | null = null; // To track the previous message date
  let messagesForToday:{ message: { local_message: { created_at: string;id:number,message:string,type:string,attachments:{id: number;
    file_name: string;
    url: string;
    file_type: any}}; }; messageDate: string; }[]=[]; // To store messages for today
  let otherMessages: { message: { local_message: { created_at: string;id:number,message:string,type:string,attachments:{id: number;
    file_name: string;
    url: string;
    file_type: any}}; }; messageDate: string; }[]=[]; // To store messages for other days

  // Separate messages into "Today" and others
  this.state.dummyMassageList.forEach((message: { local_message: { created_at: string;id:number,message:string,type:string,attachments:{id: number;
    file_name: string;
    url: string;
    file_type: any} }; }) => {
    const messageDate = this.formatDate(message.local_message.created_at);
    if (messageDate === 'Today') {
      messagesForToday.push({ message, messageDate });
    } else {
      otherMessages.push({ message, messageDate });
    }
  });

  return (
    <>
      <div
        className="message-item"
        ref={this.chatContainerRef}
        style={{
          height: "calc(100vh - 350px)",
          overflowY: "auto", // Enable vertical scrolling
          scrollbarWidth: "none",
        }}
        onScroll={this.handleScroll}
        data-test-id="onScrollData"
      >
        {this.state.isLoading && (
          <Box className="leader-container">
            <Typography variant="h6" className="leader-title">
              <CircularProgress />
            </Typography>
          </Box>
        )}

        {!this.state.isLoading && hasMessages && (
          <>
            {otherMessages.map(({ message, messageDate }, index) => {
              let renderDateLabel = false;
              if (previousMessageDate !== messageDate) {
                renderDateLabel = true;
                previousMessageDate = messageDate; // Update the previous message date
              }
              return (
                <div className="message-item" data-id={message.local_message.id}>
                <React.Fragment key={message.local_message?.id}>
                  {renderDateLabel && this.renderChatDate(messageDate)}
                  
                    <MessageContainer isUser={message.local_message?.type === 'sender'}>
                      {message.local_message?.message && (
                        <>
                          <Box className="message-bubble">
                            <Box>
                              {this.state.replyMessageData &&
                                this.state.replyMessageData.messageId === message.local_message.id && (
                                  <Box className="reply-msg-container">
                                    {this.state.replyMessageData.image ? (
                                      this.renderReplyMsgImageVideo()
                                    ) : (
                                      <Typography
                                       data-test-id="reply-msg-text"
                                        className="message-bubble-text"
                                      >
                                        {this.state.replyMessageData.message}
                                      </Typography>
                                    )}
                                  </Box>
                              )}
                              <Typography
                                data-test-id="user-msg"
                                className="message-bubble-text"
                              >
                                {message.local_message.message}
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="msg-timestamp-container">
                            <Typography className="msg-timestamp">
                              {this.formatTimeAgo2(message.local_message.created_at)}
                            </Typography>
                            {message.local_message.type !== 'sender' && (
                              <Typography
                                data-test-id="msg-reply-link"
                                className="msg-reply-link"
                                onClick={() => this.handleReplyMessageClick(message.local_message.message)}
                              >
                                Reply
                              </Typography>
                            )}
                          </Box>
                        </>
                      )}
                      {Array.isArray(message.local_message?.attachments) &&
                        message.local_message.attachments.length > 0 && (
                          <>
                            {message.local_message.attachments
                              .filter((attachment) => attachment.file_type && attachment.file_type.startsWith("image/"))
                              .length > 0 &&
                              this.renderImageGallery(
                                message.local_message.attachments.filter(
                                  (attachment) => attachment.file_type && attachment.file_type.startsWith("image/")
                                ),
                                message.local_message.created_at
                              )}

                            {message.local_message.attachments
                              .filter((attachment) => attachment.file_type && attachment.file_type.startsWith("video/"))
                              .length > 0 &&
                              this.renderVideoGallery(
                                message.local_message.attachments.filter(
                                  (attachment) => attachment.file_type && attachment.file_type.startsWith("video/")
                                ),
                                message.local_message.created_at
                              )}
                          </>
                      )}
                    </MessageContainer>
                </React.Fragment>
                </div>
              );
            })}

            {messagesForToday.length > 0 && (
              <>
                <div className="message-item">
                  {messagesForToday.map(({ message, messageDate }, index)  => (
                    <div className="message-item" data-id={message.local_message.id}>
                      <MessageContainer isUser={message.local_message?.type === 'sender'}>
                        {message.local_message?.message && (
                          <>
                            <Box className="message-bubble">
                              <Box>
                                {this.state.replyMessageData &&
                                  this.state.replyMessageData.messageId === message.local_message.id && (
                                    <Box className="reply-msg-container">
                                      {this.state.replyMessageData.image ? (
                                        this.renderReplyMsgImageVideo()
                                      ) : (
                                        <Typography
                                          className="message-bubble-text"
                                          data-test-id="reply-msg-text"
                                        >
                                          {this.state.replyMessageData.message}
                                        </Typography>
                                      )}
                                    </Box>
                                )}
                                <Typography
                                  className="message-bubble-text"
                                  data-test-id="user-msg"
                                >
                                  {message.local_message.message}
                                </Typography>
                              </Box>
                            </Box>
                            <Box className="msg-timestamp-container">
                              <Typography className="msg-timestamp">
                                {this.formatTimeAgo2(message.local_message.created_at)}
                              </Typography>
                              {message.local_message.type !== 'sender' && (
                                <Typography
                                  className="msg-reply-link"
                                  onClick={() => this.handleReplyMessageClick('','','')}
                                  data-test-id="msg-reply-link"
                                >
                                  Reply
                                </Typography>
                              )}
                            </Box>
                          </>
                        )}
                        {Array.isArray(message.local_message?.attachments) &&
                        message.local_message.attachments.length > 0 && (
                          <>
                            {message.local_message.attachments
                              .filter((attachment) => attachment.file_type && attachment.file_type.startsWith("video/"))
                              .length > 0 &&
                              this.renderVideoGallery(
                                message.local_message.attachments.filter(
                                  (attachment) => attachment.file_type && attachment.file_type.startsWith("video/")
                                ),
                                message.local_message.created_at
                              )}
                              {message.local_message.attachments
                              .filter((attachment) => attachment.file_type && attachment.file_type.startsWith("image/"))
                              .length > 0 &&
                              this.renderImageGallery(
                                message.local_message.attachments.filter(
                                  (attachment) => attachment.file_type && attachment.file_type.startsWith("image/")
                                ),
                                message.local_message.created_at
                              )}
                          </>
                      )}
                      </MessageContainer>
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};



  renderImageGallery = (images:LocalMessage["attachments"], timestamp: string) => {
         images = Array.isArray(images) ? images : [];
    if (!images || images.length === 0) {
      return null; // No attachments to display
  }

  // Filter attachments to exclude invalid entries like "Image not found"
  const validImages = images.filter(
    (images) => typeof images === 'object' && images.url !== "Image not found"
);
    // Extract URLs from valid images
    const imageUrls = validImages.map((image) => image.url);

    if (imageUrls.length === 0) {
        return null; // No valid images to display
    }

  if (validImages.length === 0) {
      return null; // No valid images to display
  }
  if (validImages.length <= 4) {
      return imageUrls.map((image, index: any) => (
        <>
          <Box key={index} style={{ display: "flex", alignItems: "flex-end" }}>
            <Box
              className="image-container"
              data-test-id="image-gallery-container"
              onClick={() => this.handleImageClick(image, "image", timestamp)}
            >
              <img
                src={image}
                alt="single-img"
                className="gallery-image"
                style={{ width: "100%", height: "100%", objectFit: "cover", cursor: "pointer" }}
              />
            </Box>
            <img src={downLoadIcon} style={{cursor:'pointer'}} data-test-id='downloadimage' onClick={() => {this.downloadMedia(imageUrls,'image')}} alt="download-icon" />

          </Box>
          <Box className="msg-timestamp-container">
            <Typography className="msg-timestamp">{this.formatTimeAgo2(timestamp)}</Typography>
          </Box>
        </>
      ));
    }
    return (
      <>
      <Box style={{ display: "flex", alignItems: "flex-end" }}>
        <ImageContainer>
          {imageUrls.slice(0, 4).map((image, index) => (
            <>
              <Box key={index} className={`image-item`}>
                <img src={image} alt={`img-${index}`} className="gallery-image" />
                {index === 3 && images.length > 4 && (
                  <Box
                    className="more-images-overlay"
                    data-test-id="more-images-overlay"
                    style={{cursor: "pointer"}}
                    onClick={() => this.handleImageClick(imageUrls, "image", timestamp)}>
                    +{images.length - 4}
                  </Box>
                )}
              </Box>
            </>
          ))}
        </ImageContainer>
        <img src={downLoadIcon} style={{cursor:'pointer'}} data-test-id='downloadimage' onClick={() => {this.downloadMedia(imageUrls,'image')}} alt="download-icon" />
       
      </Box>
       <Box className="msg-timestamp-container">
       <Typography className="msg-timestamp" style={{alignContent:'flex-end'}}>{this.formatTimeAgo2(timestamp)}</Typography>
     </Box>
     </>
    );
  };

  renderVideoGallery = (videos: LocalMessage["attachments"], timestamp: string) => {
    videos = Array.isArray(videos) ? videos : [];
    if (!videos || videos.length === 0) {
      return null; // No attachments to display
  }

  // Filter attachments to exclude invalid entries like "Image not found"
  const validImages = videos.filter(
    (videos) => typeof videos === 'object' && videos.url !== "Image not found"
);
const videoUrls = validImages.map((image) => image.url);

if (videoUrls.length === 0) {
    return null; // No valid images to display
}

  if (validImages.length === 0) {
      return null; // No valid images to display
  }
    return videoUrls && videoUrls.map((video , index: React.Key | null | undefined) => (
      <>
        <Box key={index} style={{ display: "flex", alignItems: "flex-end" }}>
          <Box
            className="video-container"
            onClick={() => this.handleImageClick(video, "video", timestamp)}
            data-test-id="video-gallery-container"
          >
            <video
              src={video}
              className="gallery-image"
              style={{ width: "280px", height: "280px", cursor: "pointer" }}
            ></video>
          </Box>
          <img src={downLoadIcon} style={{cursor:'pointer'}} data-test-id='downloadvedio' onClick={() => {this.downloadMedia(videoUrls,'video')}} alt="download-icon" />
        </Box>
        <Box className="msg-timestamp-container">
          <Typography className="msg-timestamp">{this.formatTimeAgo2(timestamp)}</Typography>
        </Box>
      </>
    ));
  };

  renderChatDate = (messageDate: any) => {
    return (
      <TimeContainer>
        <Box className="line" />
        <span className="msg-day">{messageDate}</span>
        <Box className="line" />
      </TimeContainer>
    );
  };

  renderReplyBox = () => {
    if (!this.state.isReplyMessage) return null;
    return (
      <MessageReplyContainer>
        <Box className="reply-message-box">
          <Typography className="reply-message-text">
            {this.state.replyMessageData.message}
          </Typography>
          {this.state.replyMessageData.image &&
            (this.state.replyMessageData.type === "image" ? (
              <img
                className="reply-message-image"
                src={this.state.replyMessageData.image}
              />
            ) : (
              <video
                src={this.state.replyMessageData.image}
                className="reply-message-image"
              ></video>
            ))}
        </Box>
        <IconButton onClick={this.handleReplyClose} data-test-id="reply-close">
          <img src={closeIcon} alt="close-icon" />
        </IconButton>
      </MessageReplyContainer>
    );
  };

  renderViewImgVideo = () => {
    return (
      <CustomViewImage
        handleNextClick={this.handleImageDialogNext}
        handlePrevClick={this.handleImageDialogPrev}
        handleDialogClose={this.handleImageCloseDialog}
        open={this.state.viewImageDialog.isImageDialogOpen}
        userName={this.state.dummyUserName}
        viewImageDialog={this.state.viewImageDialog}
        userProfile={this.state.imageUrl}
        data-test-id="image-model"
      />
    );
  };
  renderUploadDocument = () => {
    return (
      <CustomDialog
        open={this.state.isUploadDocumentDialogOpen}
        onClose={this.handleUploadDocumentCloseDialog}
        title="Upload Document"
        closeIconProp={closeImageIcon}
        className="chat-upload-document-title"
        data-test-id="upload-document-dialog"
      >
        <FileUploadContainer>
          <Dropzone onDrop={this.handleDrop} data-test-id="drop-zone">
            {({
              getRootProps,
              getInputProps,
            }: {
              getRootProps: (props?: DropzoneRootProps) => DropzoneRootProps;
              getInputProps: (props?: DropzoneInputProps) => DropzoneInputProps;
            }) => (
              <Box className="drop-zone-container" {...getRootProps()}>
                <input {...getInputProps()} accept=".png,.jpeg,.mp4" />
                <Box className="drop-zone-info">
                  <img
                    src={uploadDocumentIcon}
                    alt="upload icon"
                    className="upload-icon"
                  />
                  <Typography className="drag-drop-text">
                    Drag Files to Upload
                  </Typography>
                  <Typography className="support-file">
                    Supported Format : PNG, JPEG, MP4
                  </Typography>
                </Box>
              </Box>
            )}
          </Dropzone>
          {this.state.uploadDocumentTypeError && <Typography className="file-error">{this.state.uploadDocumentTypeError}</Typography>}
          
        
          <Box className="file-list-container">
            {this.state.uploadDocumentData.uploadFiles.length > 0 && (
              <Typography className="upload-text">Uploading</Typography>
            )}
            {this.state.uploadDocumentData.uploadFiles.map(
              (uploadFile, index) => (
                <Box className="upload-progress-container">
                  <Box className="upload-progress-file">
                    <Typography className="upload-progress-file-name">
                      {uploadFile.file.name}
                    </Typography>
                    <IconButton
                      onClick={() =>
                        this.handleUploadDocumentCloseClick(uploadFile.file)
                      }
                      data-test-id="uploading-file-close"
                    >
                      <img className="upload-file-close-icon" src={purpleCloseIcon} alt="Attach-File-Icon" />
                    </IconButton>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={uploadFile.progress}
                    className="upload-file-progressbar"
                    color="secondary"
                  />
                </Box>
              )
            )}
          </Box>
        
        
          <Box className="file-list-container">
            {this.state.uploadDocumentData.completedFiles.length > 0 && (
              <Typography className="upload-text">Uploaded</Typography>
            )}
            {this.state.uploadDocumentData.completedFiles.length > 0 &&
              this.state.uploadDocumentData.completedFiles.map(
                (file, index) => (
                  <Box className="upload-progress-container">
                    <Box className="upload-progress-file">
                      <Typography className="upload-progress-file-name">
                        {file.name}
                      </Typography>
                      <IconButton
                        onClick={() =>
                          this.handleUploadDocumentCloseClick(file)
                        }
                        data-test-id="uploaded-file-close"
                      >
                        <img className="upload-file-close-icon" src={purpleCloseIcon} alt="Attach-File-Icon" />
                      </IconButton>
                    </Box>
                    <LinearProgress color="secondary" variant="determinate" value={100} />
                  </Box>
                )
              )}
          </Box>
     

          

          {this.state.uploadDocumentData.completedFiles.length > 0 && (
            <Box className="upload-file-btn-container">
              <Button className="upload-file-btn" onClick={this.handleUploadFileClick} data-test-id="upload-file-btn">
                Upload Files
              </Button>
              </Box>
            )}
        </FileUploadContainer>
      </CustomDialog>
    );
  };

  renderDefaultSection = () => {
    return (
      <DefaultContainer>
        <DefaultImgContainer>
          <img src={messageIcon} />
        </DefaultImgContainer>
        <Box>
          <DefaultTypography>No messages yet!</DefaultTypography>
        </Box>
      </DefaultContainer>
    )
  }

  renderLoaderForView = () => {
    return (
      <Box style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <StyledLoader />
      </Box>
    )
  }
  renderNonPaymentMessageModal=()=>{
    return(
      <StyledCreatedPaymentPopUp 
    fullWidth
    open={this.state.OpenMessagePopUp}
    data-test-id="create-message-popup">
      <Box className="containerTitle">
        <CloseMessageWrapper>
          <CloseIconButton onClick={() => this.handleClosePaymentMessageModal()}
            data-test-id="close-modal" style={{margin:10}}>
            <img src={closemodal} alt="close-icon" />
          </CloseIconButton>             
        </CloseMessageWrapper>
        <CreateNonPaymentInfoWrapper>
          <Typography className="TitleMessage">
            To start chat again need to book new service!
          </Typography>
        </CreateNonPaymentInfoWrapper>
      </Box>
    </StyledCreatedPaymentPopUp>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {dummyMassageList, isLoading,dummyMsgDate} = this.state;
    const hasMessagesInfo = dummyMassageList.length > 0;
    return (
      <ThemeProvider theme={theme}>
       <div  ref={this.chatContainerRef}
       >
        <ViewChatContainer 
       >
          {this.renderUserNameWithChat()}
          <Box
          
            style={{
              padding: "10px 35px",
              display: "flex",
              flexDirection: "column",
              height: "530px",
              overflowY: "hidden",
              scrollbarWidth:'none',
              marginBottom:'10'
            }}
          >
            
            {hasMessagesInfo && !isLoading ?
              (
                <Box>
                  {this.renderChatDate(dummyMsgDate)}
                  {this.renderMessageList()}
                  {this.renderViewImgVideo()}
                  {this.renderUploadDocument()}
                </Box>
              )
              :
              (<></>)
            }
            {!hasMessagesInfo && !isLoading ?
              (
                this.renderDefaultSection()
              )
              :
              (<></>)
            }
            {!hasMessagesInfo && isLoading ?
              (
                this.renderLoaderForView()
              )
              :
              (<></>)
            }
      
            
          </Box>
          <ReplyWriteContainer>
              {this.renderReplyBox()}
              <MessageTypingContainer >
                <Box className="message-input">
                  <TextField
                    fullWidth
                    id="email"
                    placeholder="Type Message..."
                    type="text"
                    data-test-id="Input-message"
                    variant="outlined"
                    onChange={this.handleInputChange}
                    value={this.state.dummyNewMessage}
                    onKeyDown={this.handleKeyDown} 
                  />
                </Box>
                <IconButton
                  onClick={this.handleInsertImage}
                  className="attach-file-icon-btn"
                  data-test-id="attach-file-icon"
                >
                  <img src={attachFileIcon} alt="Attach-File-Icon" />
                </IconButton>
                <IconButton
                  onClick={this.dummyHandleSendMessage}
                  data-test-id="send-icon"
                >
                  <img src={sendIcon} alt="send-icon" />
                </IconButton>
              </MessageTypingContainer>
            </ReplyWriteContainer>
        </ViewChatContainer>
        </div>
        {this.renderNonPaymentMessageModal()}
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledLoader = styled(CircularProgress)({
  color: "#6F05EA"
})

const DefaultContainer = styled(Box)({
  display: "flex",
  height: "100%",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 10
})

const DefaultImgContainer = styled(Box)({
  "& img": {
    width: 47,
    height: 47,
    "@media (max-width: 960px)": {
      width: 42,
      height: 42,
    },
    "@media (max-width: 600px)": {
      width: 37,
      height: 37,
    },
    "@media (max-width: 450px)": {
      width: 32,
      height: 32,
    },
  },
})

const DefaultTypography = styled(Typography)({
  fontSize: 24,
  textAlign: "center",
  color: "#B7B7B7",
  lineHeight: "22px",
  fontWeight: 500,
  "@media (max-width: 960px)": {
    fontSize: 23,
  },
  "@media (max-width: 600px)": {
    fontSize: 22,
  },
  "@media (max-width: 450px)": {
    fontSize: 20,
  },
})
// Customizable Area End