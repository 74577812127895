import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Popover,
  ClickAwayListener,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import {
  headerLogo,
  languageIcon,
} from "../../components/src/appHeader-assets";
import { styled } from "@material-ui/core/styles";
import {
  chatIcon,
} from "../../blocks/customisableuserprofiles/src/assets";
import { getStorageData, removeStorageData, setStorageData } from "../../framework/src/Utilities";
import { headerCalendarIcon, headerCalendarSelectIcon, headerFootprintIcon, headerFootprintSelectIcon, headerLogoutIcon, headerLogoutSelectIcon, headerSettingIcon, headerSettingSelectIcon, headerWalletIcon, headerWalletSelectIcon } from "./assets";
import { SimpleDialog } from "./HelperComponents";

interface IAppHeaderProp {
  navigation?: any;
  handleCustomerHeaderSelect?: (selectedValue: string) => void;
  isProfile?: boolean;
}

const BoxStyle = styled(Box)(({ theme }) => ({
  padding: "42px 152px",
  boxSizing:"border-box",
  display: "flex",
  flexDirection: "row",
  background: "linear-gradient(to right, #6928F2, #884FFF)",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("lg")]: {
    padding: "22px 100px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "22px 85px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "14px 60px",
  },
  [theme.breakpoints.down("xs")]: {
    padding: "12px 40px",
  },
  "& .Header-container": {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  "& .Header-Name": {
    display: "flex",
    gap: "8px",
    cursor:"pointer",
  },
  "& .Header-language": {
    fontSize: "20px",
    fontWeight: 700,
    color: "#FFFFFF",
    [theme.breakpoints.down("lg")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "17px",
    },
  },
  "& .Header-Register": {
    color: "#FFFFFF",
    cursor:"pointer",
    fontSize: "20px",
    padding: "9.69px 47px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    "& .MuiTypography-colorPrimary": {
      color: "#FFFFFF",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "17px",
    },
  },
  "& .Header-btn-container": {
    display: "flex",
    gap: "20px",
  },
  "& .Login-Btn":{
    padding:"10 57px !important",
    [theme.breakpoints.down("lg")]: {
      padding: "0px 57px !important",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0px 42.5px !important",
    },
  },
  "& .Header-Btn": {
    borderRadius: "35px",
    padding: "10px 49.5px",
    backgroundColor: "#FFFFFF",
    color: "#6200EA",
    textTransform: "none",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: 700,
    cursor:"pointer",
    [theme.breakpoints.down("lg")]: {
      padding: "0px 49.5px",
      display: "flex",
      alignItems: "center",
      fontSize: "15px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0px 35px",
      display: "flex",
      alignItems: "center",
      fontSize: "15px",
    },
  },
  "& .MuiIconButton-root": {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      color: "#FFFFFF",
    },
  },
  "& .Header-menu": {
    display: "flex",
    gap: "20px",
    [theme.breakpoints.down("md")]: {
      gap: "15px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  "& .header-logo": {
    [theme.breakpoints.down("lg")]: {
      height: "30px",
    },
    [theme.breakpoints.down("md")]: {
      height: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "20px",
    },
  },
}));
const StylesBoxLoggedIn = styled(Box)(({ theme }) => ({
  "& .header-profile-name": {
    fontSize: "24px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
  },
  "& .header-pts": {
    backgroundColor: "#FFCE00",
    padding: "10px 36px",
    borderRadius: "35px",
    fontWeight: "600",
    fontSize: "14px",
    display:"none",
    [theme.breakpoints.down(700)]: {
      padding: "10px 16px",
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 16px",
      fontSize: "12px",
    },
    [theme.breakpoints.down(450)]: {
      display: "none",
    },
  },
  "& .header-logo": {
    [theme.breakpoints.down("lg")]: {
      height: "30px",
    },
    [theme.breakpoints.down("md")]: {
      height: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "20px",
    },
  },

  "& .header-user": {
    display: "flex",
    alignItems: "center",
    gap: "30px",
    [theme.breakpoints.down(700)]: {
      gap: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      gap: "20px",
    },
    [theme.breakpoints.down(450)]: {
      gap: "10px",
    },
  },
  "& .user-profile-img": {
    width: "42px",
    height: "42px",
    borderRadius: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "36px",
      height: "36px",
    },
  },
  "& .Header-language": {
    fontSize: "20px !important",
    fontWeight: 700,
    color: "#FFFFFF",
    [theme.breakpoints.down("lg")]: {
      fontSize: "18px !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "17px !important",
    },
  },
  "& .container": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 152px",
    [theme.breakpoints.down("lg")]: {
      padding: "0px 100px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0px 85px !important",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px 60px !important",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px 40px !important",
    },
  },
  [theme.breakpoints.down("md")]: { 
    minHeight: "88.8px !important",
  },
  [theme.breakpoints.down("sm")]: {
    minHeight: "76px !important",
  },
  [theme.breakpoints.down("xs")]: {
    minHeight: "72px !important",
  },
}));

const PopoverContainer = styled(Popover)(({ theme }) => ({
  marginTop: "96px",
  "& .MuiPopover-paper": {
    top: "0 !important",
    width:"197px",
  },
  "& .MuiMenuItem-root": {
    padding: "8px 30px",
    color: "#6F05EA",
    fontSize: "14px",
    fontWeight: "600",
  },
  "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
    background: "linear-gradient(to right,#6928F2, #884FFF)",
    color: "#fff !important",
    fontSize: "14px",
    fontWeight: "600",
  },
  "& .MuiListItemIcon-root":{
    minWidth:"46px"
  }
}));

export function AppHeader(props: IAppHeaderProp) {
  const baseURL = new URL(document.URL).href.split('/').pop();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [rerender, setRerender] = useState(false);
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [isLoggedOutOpen, setIsLoggedOutOpen] = useState(false);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [selectMenuForLoggedInUser, setSelectMenuForLoggedInUser] =
    useState<string>("");

  
    const [anchorElOpenForLogged, setAnchorElOpenForLogged] = useState<null | HTMLElement>(null);

    const handleMenuOpenForLoggedInUser = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorElOpenForLogged(event.currentTarget);
   };

  const handleMenuCloseForLoggedInUser =  () => {
    setAnchorElOpenForLogged(null);
  };
 
  useEffect(()=>{
    const naviagteToHome = async () => {
      const currentPath = window.location.pathname;
      const homePath = '/';
      if (isLoggedOut) {  
        if (currentPath === homePath) {
          setRerender(prevState => !prevState);
        } else {
          props.navigation.navigate('Home'); 
        }
      }
    }; 
    naviagteToHome();
  },[isLoggedOut ,props.navigation]);

  const handleLogout = () => {
    setIsLoggedOutOpen(true);
  }
  
  const handleSureLogout = () => {
    setIsLoggedOutOpen(false);
    localStorage.clear();
    sessionStorage.clear();
    setIsLoggedOut(true);
    props.navigation.navigate("/");
  }

  const handleCancelLogout = () => {
    setIsLoggedOutOpen(false)
  }

  const handleSelectForLoggedInUser = async (value: string) => {
    let selectedValue:string = await getStorageData("selectedValueCustomer") || "0";
    switch (value) {
      case "Calendar":
        setStorageData("selectedValueCustomer", "0");
        selectedValue = "0";
        break;
      case "My pets":
        setStorageData("selectedValueCustomer", "1");
        selectedValue = "1";
        break;
      case "Wallet":
        setStorageData("selectedValueCustomer", "2");
        selectedValue = "2";
        break;
      case "Settings":
        setStorageData("selectedValueCustomer", "3");
        selectedValue = "3";
        break;
      case "Logout": 
        handleLogout();
        break;
      default:
        setStorageData("selectedValueCustomer", "0");
        selectedValue = "0";
        break;
    }
    if (value !== "Logout") {
      setSelectMenuForLoggedInUser(value);
    }
    if (props.isProfile) {
      props.handleCustomerHeaderSelect === undefined
        ? undefined
        : props.handleCustomerHeaderSelect(selectedValue);
    } else if (value !== "Logout") {
      props.navigation.navigate("UserProfile");
    }
    setAnchorElOpenForLogged(null)
  };

  useEffect(() => {
    setSelectMenuForLoggedInUser(dropDownValue());
  },[localStorage.getItem("selectedValueCustomer")]);

  const dropDownValue  = () => {
    switch (localStorage.getItem("selectedValueCustomer")) {
      case "0":
        return "Calendar";
      case "1":
        return "My pets";
      case "2":
        return "Wallet";
      case "3":
        return "Settings";
      default:
        return "";
    }
  }
  const isLoggedIn = localStorage.getItem("isUserLogin");
  const isHiddenFeature = false;
  const pathList = ["login", "signup", "forgotpassword", "newpassword", "vendorsignup"];
  let pathName = baseURL;
  if(baseURL?.toLocaleLowerCase().includes("newpassword")){
    pathName = baseURL?.split('?')[0]
  }
  const isShowLoginHeader = !pathList.includes(pathName?.toLocaleLowerCase() || "");
  const isVendor = localStorage.getItem("login_user_role") === "vendor";

  const handleMenuClick = (pathName:string) => {
    props.navigation.navigate(pathName);
    handleClose();
  }

  const handleLogoClick = () => {
    const targetRoute = isVendor ? "Cfentrepriseaccount" : "Home";
    if (targetRoute === "Home") {
      setSelectMenuForLoggedInUser("")
      removeStorageData("selectedValueCustomer")
    }
    props?.navigation?.navigate(targetRoute);
  };

  return (
    <>
      {isLoggedIn !== "true" || !isShowLoginHeader ? (
        <BoxStyle>
          <Box style={{cursor:"pointer"}}>
            <img onClick={() => {props.navigation.navigate("Home")}} className="header-logo" src={headerLogo} alt="Logo" />
          </Box>
          <Box className="Header-container">
            {/* <Box className="Header-Name">
              <img src={languageIcon} alt="Language Icon" />
              <Typography className="Header-language">EN</Typography>
            </Box>  --- full build*/}
            <Box className="Header-menu">
              <Typography className="Header-Register">
                <Box onClick={() =>  props.navigation.navigate("EmailAccountVendorRegistration")}>
                  Register your business
                </Box>
              </Typography>
              <Box className="Header-btn-container">
                <Box className="Header-Btn" onClick={() =>  props.navigation.navigate("EmailAccountRegistration")}>
                  Sign up
                </Box>
                <Box className="Header-Btn Login-Btn" onClick={() =>  props.navigation.navigate("EmailAccountLoginBlock")}>
                  Login
                </Box>
              </Box>
            </Box>
            <IconButton
              aria-controls={open ? "header-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="header-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              keepMounted
            >
              <MenuItem onClick={() => handleMenuClick("EmailAccountVendorRegistration")}>
                <Box>
                  Register your business
                </Box>
              </MenuItem>
              <MenuItem onClick={() => handleMenuClick("EmailAccountRegistration")}>
                <Box>
                  Sign up
                </Box>
              </MenuItem>
              <MenuItem onClick={() => handleMenuClick("EmailAccountLoginBlock")}>
                <Box>
                  Login
                </Box>
              </MenuItem>
            </Menu>
          </Box>
        </BoxStyle>
      ) : (
        <StylesBoxLoggedIn style={styles.header.wrapper}>
          <Box  className="container">
            <Box style={{cursor:"pointer"}}>
              <img onClick={handleLogoClick} className="header-logo" src={headerLogo} />
            </Box>
            <Box className="header-user">
              {/* <Box style={styles.header.languageContainer}>
                <img src={languageIcon} alt="Language Icon" />
                <Typography
                  style={styles.header.languageName}
                  className="Header-language"
                >
                  EN
                </Typography>
              </Box> --- full build*/}
              
                <Box className="header-pts" style={{cursor:"pointer"}}>125 pts</Box>
                <ClickAwayListener onClickAway={handleMenuCloseForLoggedInUser}>
                  <Box
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                  >
                    <Box
                      aria-controls="simple-menu"
                      style={styles.header.profile}
                      aria-haspopup="true"
                      onClick={handleMenuOpenForLoggedInUser}
                    >
                      <img
                        src={localStorage.getItem("login_user_profile") as string}
                        alt=""
                        className="user-profile-img"
                      />
                      <Typography className="header-profile-name">
                        {localStorage.getItem("login_user_name")}
                      </Typography>
                    </Box>
                    <PopoverContainer
                      id="simple-menu"
                      anchorEl={anchorElOpenForLogged}
                      open={Boolean(anchorElOpenForLogged)}
                      onClose={() => { setAnchorElOpenForLogged(null) }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <MenuItem
                        onClick={(event) =>
                          handleSelectForLoggedInUser("Calendar")
                        }
                        selected={
                          selectMenuForLoggedInUser === "Calendar" ? true : false
                        }
                      >
                        <ListItemIcon>
                          <img
                            src={
                              selectMenuForLoggedInUser === "Calendar"
                                ? headerCalendarSelectIcon
                                : headerCalendarIcon
                            }
                            alt="document"
                            className="header-menu-img"
                          />
                        </ListItemIcon>
                        <Typography variant="inherit">Calendar</Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={(event) =>
                          handleSelectForLoggedInUser("My pets")
                        }
                        selected={
                          selectMenuForLoggedInUser === "My pets" ? true : false
                        }
                      >
                        <ListItemIcon>
                          <img
                            src={
                              selectMenuForLoggedInUser === "My pets"
                                ? headerFootprintSelectIcon
                                : headerFootprintIcon
                            }
                            alt="my pets"
                            className="header-menu-img"
                          />
                        </ListItemIcon>
                        <Typography variant="inherit">My pets</Typography>
                      </MenuItem>
                      {isHiddenFeature && 
                      <MenuItem
                        onClick={(event) => handleSelectForLoggedInUser("Wallet")}
                        selected={
                          selectMenuForLoggedInUser === "Wallet" ? true : false
                        }
                      >
                        <ListItemIcon>
                          <img
                            src={
                              selectMenuForLoggedInUser === "Wallet"
                                ? headerWalletSelectIcon
                                : headerWalletIcon
                            }
                            alt="Wallet"
                            className="header-menu-img"
                          />
                        </ListItemIcon>
                        <Typography variant="inherit">Wallet</Typography>
                      </MenuItem>}
                      <MenuItem
                        onClick={(event) =>
                          handleSelectForLoggedInUser("Settings")
                        }
                        selected={
                          selectMenuForLoggedInUser === "Settings" ? true : false
                        }
                      >
                        <ListItemIcon>
                          <img
                            src={
                              selectMenuForLoggedInUser === "Settings"
                                ? headerSettingSelectIcon
                                : headerSettingIcon
                            }
                            alt="settings"
                            className="header-menu-img"
                          />
                        </ListItemIcon>
                        <Typography variant="inherit">Settings</Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={(event) => handleSelectForLoggedInUser("Logout")}
                        selected={
                          selectMenuForLoggedInUser === "Logout" ? true : false
                        }
                      >
                        <ListItemIcon>
                          <img
                            src={
                              selectMenuForLoggedInUser === "Logout"
                                ? headerLogoutSelectIcon
                                : headerLogoutIcon
                            }
                            alt="document"
                            className="header-menu-img"
                          />
                        </ListItemIcon>
                        <Typography variant="inherit">Logout</Typography>
                      </MenuItem>
                    </PopoverContainer>
                  </Box>
                </ClickAwayListener>
                <img
                src={chatIcon}
                style={{cursor:"pointer"}}
                onClick={() => {props.navigation.navigate("ChatWeb")}}
              />
              </Box>
          </Box>
            {isLoggedOutOpen && 
              <SimpleDialog 
                open={isLoggedOutOpen} 
                message={"You're Logging Out of Petzai, are you sure?"} 
                showRightBtn={true} showLeftBtn={true} 
                leftBtnTitle="Yes, I'm Sure" 
                rightBtnTitle="No, keep me Logged In" 
                handleRightClick={handleCancelLogout}
                handleLeftClick={handleSureLogout}
              />
            }
        </StylesBoxLoggedIn>
      )}
    </>
  );
}

const styles = {
  header: {
    wrapper: {
      minHeight: "127px",
      display: "flex",
      alignItems: "center",
      background: "linear-gradient(to right,#6928F2, #884FFF)",
    },
    
    user: {
      display: "flex",
      alignItems: "center",
      gap: "30px",
    },
    profile: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
      color: "#fff",
      cursor:"pointer"
    },
    languageContainer: {
      display: "flex",
      gap: "8px",
      cursor:"pointer"
    },
    languageName: {
      fontSize: "20px",
      fontWeight: 700,
      color: "#FFFFFF",
    },
  },
};
