import { Box } from '@material-ui/core';
import React from 'react'
import { GenericFormikProps } from '../../types';
import StartDateChange from './StartDateChange';
import EndDateChange from './EndDateChange';
import DayOfWeekChange from './DayOfWeekChange';


const PetSittingContent = (props: GenericFormikProps) => {
    const { event, isAll, isReccurence } = props;
    const isPetSitting = event.service.service_type === "Pet Sitting";
    const isSelectedAll = isAll && isReccurence;

    if(!isPetSitting) {
        return null;
    }

   return (
        <Box className="form-wrapper">
            <StartDateChange {...props} isReccurence={isSelectedAll}/>
            {isSelectedAll && <EndDateChange {...props} />}
            {isSelectedAll && <DayOfWeekChange {...props} />}
        </Box>
   ) 
}

export default PetSittingContent;