import { Box } from '@material-ui/core';
import React from 'react'
import { GenericFormikProps } from '../../types';
import StartDateChange from './StartDateChange';


const TrainingContent = (props: GenericFormikProps) => {
    const { event } = props;
    const isTraining = event.service.service_type === "Training";

    if(!isTraining) {
        return null;
    }

   return (
        <Box className="form-wrapper">
          <StartDateChange {...props} isReccurence={false}/>
        </Box>
   ) 
}

export default TrainingContent;