import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { scrollToSection } from "../../../../components/src/HelperUtils";
import i18n from "../../../../components/src/i18next/i18n";
interface IGalleryModal {
  image_url: string
}

interface IGalleySubService {
  capacity:string, 
  price:string, 
  description:string
}
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  // Customizable Area Start
  handelCloseClick: () => void;
  galleryData: IGalleryModal[];
  isSubService:boolean;
  subServiceData?:IGalleySubService;
  isGalleryLoading?:boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  viewImageId: string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ServiceGalleryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      viewImageId: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handleImageClick = (url:string) => {
    this.setState({viewImageId:url});
  }
  handleBackImageClick = () => {
    this.setState({viewImageId:""})
  }

  handleReserveClick = () => {
    this.props.handelCloseClick()
    scrollToSection("book-now-section")
  }

  transGallery = (galleryKey: string) => {
    return i18n.t(galleryKey, { ns: "scheduling" });
  }
  // Customizable Area End
}