import { Box } from '@material-ui/core';
import React from 'react'
import Field from '../Field';
import { GenericFormikProps } from '../../types';
export const configJSON = require("../../config.js");


const RecurrenceChange = ({ event, errors, touched, getFieldProps, setFieldValue, values, renderCustomField, handleBlur }: GenericFormikProps) => {
    const getOldRecurrence = () => {
        if(event.week_days?.length) {
          return configJSON.reccurenceList[event.week_days.length - 1].title
        }
      }

    return (
        <Box className="form-row">
            <Box style={{ width: '100%'}}>
                <Field title="Old Recurrence" value={getOldRecurrence()} withOpacity />
            </Box>
            <Box style={{ position: "relative", width: '100%' }}>
                {renderCustomField({
                handleBlur,
                errors,
                field: "recurrence",
                disabledField: "isEditRequestChange",
                values,
                list: configJSON.reccurenceList.map((item: any) => ({ label: item.title, value: item.id })),
                touched,
                handleChange: (event: any) => {
                    setFieldValue("recurrence", event.target.value);
                },
                getFieldProps,
                editLabel: {
                    enabled: true,
                    styles: { fontSize: "12px" },
                },
                })}
            </Box>
        </Box>
    )
}

export default RecurrenceChange;