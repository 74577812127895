import React from "react";
// Customizable Area Start
import {
    Box, styled, IconButton, Typography, Button, Checkbox, Select, FormControlLabel, MenuItem,
    Grid, FormLabel, TextField, InputAdornment, Dialog, Input, FormHelperText, Avatar,
    CircularProgress
} from "@material-ui/core";
import ViewEmployeeController, { Props } from "./ViewEmployeeController.web";
import NavigationMenu from "../../../navigationmenu/src/NavigationMenu.web";
import { AppFooter } from "../../../../components/src/AppFooter/appFooter";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import {
    UserIcon, CommunityIcon, EmailIcon, DateCalendar, checkedSM, unCheckedSM, CheckedCheckBox, UnCheckedCheckBox,
    uploadImage
} from "../assets";
import { checkCondition, disableEndTime, generateTimeRange, returnTruthyString } from "../../../../components/src/HelperUtils";
import { SelectProps } from '@material-ui/core/Select';
import PhoneInput from "react-phone-input-2";
import DatePicker from "react-datepicker";
import CustomerCalendar from "../../../customisableuserprofiles/src/components/CustomerCalendar";
import { Shedule } from "../../../customisableuserprofiles/src/CustomisableUserProfilesController";
import { Views } from "react-big-calendar";
import { LoadingButton, SimpleDialog } from "../../../../components/src/HelperComponents";

const selectMenuProps: Partial<SelectProps['MenuProps']> = {
    getContentAnchorEl: null,
    anchorOrigin: {
        horizontal: "left",
        vertical: "bottom",
    },
    transformOrigin: {
        horizontal: "left",
        vertical: "top",
    },
};

const Adornment = (imgSrc: string) => (
    <InputAdornment position="start"><img src={imgSrc} /></InputAdornment>
);

const CustomSelectInputProps = <Input style={{ marginTop: 0 }} startAdornment={Adornment(CommunityIcon)} />

const CustomSelectMenuProps = {
    ...selectMenuProps,
    PaperProps: {
        style: {
            maxHeight: 229
        }
    }
}
// Customizable Area End

export default class ViewEmployee extends ViewEmployeeController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderEmployeeProfile = () => {
        const { getEmployeeLoading } = this.state
        if (getEmployeeLoading) return this.renderLoader()
        return (
            <>
                <EmployeeWrapper>
                    <DetailsWrapper>
                        <FormWrapper>
                            {this.renderForm()}
                            {this.renderAvailability()}
                            {this.renderFormButtons()}
                        </FormWrapper>
                    </DetailsWrapper>
                </EmployeeWrapper>
            </>
        )
    }
    renderLoader = () => {
        return (
            <EmployeeWrapper>
                <DetailsWrapper>
                    <ImageWrapper>
                        <CircularProgress size={32} style={{ color: "#6F05EA" }} />
                    </ImageWrapper>
                </DetailsWrapper>
            </EmployeeWrapper>
        )
    }
    photoField = () => {
        const { isEditOn } = this.state
        return (
            <ImageWrapper>
                {isEditOn ? this.updatePhotoField() : this.viewPhotoField()}
            </ImageWrapper>
        )
    }
    updatePhotoField = () => {
        const { isEditOn, employeeForm, formErrors } = this.state
        return (
            <>
                <UploadWrapper>
                    <PhotoBox>
                        <ProfileAvatar src={employeeForm.photo.url} alt="img" />
                        <UploadImg src={uploadImage} id="upload-img-id" />
                        <input
                            type="file"
                            title=""
                            accept="image/png, image/jpeg"
                            onChange={this.setFieldPhoto}
                            data-testid="upload-img-edit"
                            style={style.photoImgInput}
                            disabled={!isEditOn}
                        />
                    </PhotoBox>
                </UploadWrapper>
                <ErrorMsg error>{formErrors.photo}</ErrorMsg>
            </>
        )
    }
    viewPhotoField = () => {
        const { employeeForm } = this.state
        return (
            <>
                <ViewProfileAvatar src={employeeForm.photo.url} alt="img" />
            </>
        )
    }
    nameField = () => {
        const { isEditOn, employeeForm, formErrors } = this.state
        return (
            <GridItem item md={6} sm={12} xs={12}>
                <CustomFormLabel>Employee Name</CustomFormLabel>
                <CustomField
                    className="custom-field"
                    type="text"
                    placeholder="Name"
                    value={employeeForm.name}
                    onChange={(event) => this.setFieldName(event.target.value)}
                    InputProps={{ startAdornment: Adornment(UserIcon) }}
                    data-testid="name-field"
                    disabled={!isEditOn}
                />
                <ErrorMsg error>{formErrors.name}</ErrorMsg>
            </GridItem>
        )
    }
    roleField = () => {
        const { isEditOn, employeeForm, formErrors, userRoleList } = this.state
        return (
            <GridItem item md={6} sm={12} xs={12}>
                <CustomFormLabel>Select Role</CustomFormLabel>
                <CustomField
                    className="custom-field"
                    select
                    SelectProps={{
                        IconComponent: CustomMenuIcon,
                        displayEmpty: true,
                        MenuProps: selectMenuProps,
                        classes: {
                            root: "custom-select-root",
                        },
                        renderValue(value) {
                            const roleValue = userRoleList.find((role) => String(role.id) == (value as string))?.name
                            if (roleValue) {
                                return <ValueFont>{returnTruthyString(roleValue)}</ValueFont>;
                            }
                            return <PlaceHolderFont>Role</PlaceHolderFont> // PLACEHOLDER CASE
                        },
                    }}
                    value={employeeForm.roleId}
                    onChange={(event) => this.setFieldRole(event.target.value)}
                    InputProps={{ startAdornment: Adornment(CommunityIcon) }}
                    data-testid="role-field"
                    disabled={!isEditOn}
                >
                    <MenuFont value={""} disabled>Role</MenuFont>
                    {userRoleList.map((role) => {
                        return (
                            <MenuFont value={role.id.toString()}>{role.name}</MenuFont>
                        )
                    })}
                </CustomField>
                <ErrorMsg error>{formErrors.roleId}</ErrorMsg>
            </GridItem>
        )
    }
    emailField = () => {
        const { isEditOn, employeeForm, formErrors } = this.state
        return (
            <GridItem item md={6} sm={12} xs={12}>
                <CustomFormLabel>Email</CustomFormLabel>
                <CustomField
                    className="custom-field"
                    type="email"
                    placeholder="Email address"
                    value={employeeForm.email}
                    onChange={(event) => this.setFieldEmail(event.target.value)}
                    InputProps={{ startAdornment: Adornment(EmailIcon) }}
                    data-testid="email-field"
                    disabled={!isEditOn}
                />
                <ErrorMsg error>{formErrors.email}</ErrorMsg>
            </GridItem>
        )
    }
    phoneField = () => {
        const { isEditOn, employeeForm, formErrors } = this.state
        return (
            <GridItem item md={6} sm={12} xs={12}>
                <CustomFormLabel>Phone</CustomFormLabel>
                <PhoneInput
                    buttonStyle={style.phoneButtonStyle}
                    inputStyle={style.phoneInputStyle}
                    dropdownStyle={style.phoneDropDownStyle}
                    placeholder="Your contact number"
                    value={employeeForm.phone}
                    onChange={(value) => this.setFieldPhone(value)}
                    data-testid="phone-field"
                    disabled={!isEditOn}
                />
                <ErrorMsg error>{formErrors.phone}</ErrorMsg>
            </GridItem>
        )
    }
    serviceField = () => {
        const { serviceList, employeeForm, isEditOn, formErrors } = this.state
        return (
            <GridItem item md={6} sm={12} xs={12}>
                <CustomFormLabel>Select Service</CustomFormLabel>
                <CustomField
                    select
                    InputProps={{ startAdornment: Adornment(CommunityIcon) }}
                    className="custom-field"
                    SelectProps={{
                        MenuProps: selectMenuProps,
                        IconComponent: CustomMenuIcon,
                        classes: {
                            root: "custom-select-root",
                        },
                        displayEmpty: true,
                        renderValue(value) {
                            const selectedService = serviceList.find((service) => String(service.service_id) == (value as string))?.service_name
                            if (selectedService) {
                                return <ValueFont>{returnTruthyString(selectedService)}</ValueFont>;
                            }
                            return <PlaceHolderFont>Service</PlaceHolderFont> // PLACEHOLDER CASE
                        },
                    }}
                    value={employeeForm.serviceId}
                    onChange={(event) => this.setFieldService(event.target.value)}
                    data-testid="service-field"
                    disabled={!isEditOn}
                >
                    <MenuFont value={""} disabled>Service</MenuFont>
                    {serviceList.map((service) => {
                        return (
                            <MenuFont value={service.service_id.toString()}>{service.service_name}</MenuFont>
                        )
                    })}
                </CustomField>
                <ErrorMsg error>{formErrors.serviceId}</ErrorMsg>
            </GridItem>
        )
    }
    subServiceField = () => {
        const { subServiceList, employeeForm, isEditOn, formErrors } = this.state
        const renderLabelTags = (selectedIds: string[]) => {
            if (selectedIds.length === 0) {
                return <PlaceHolderFont>Sub Service</PlaceHolderFont>; // PLACEHOLDER CASE
            }
            return (
                <ValueFont>
                    {selectedIds.map((eachId) => {
                        const subService = subServiceList.find((service) => String(service.sub_service_id) == String(eachId));
                        const subServiceName = checkCondition(Boolean(subService), subService?.sub_service_name, "") as string
                        return subServiceName
                    }).join(', ')}
                </ValueFont>
            )
        }
        return (
            <GridItem item md={6} sm={12} xs={12}>
                <CustomFormLabel>Select Sub Service</CustomFormLabel>
                <MultiSelect
                    displayEmpty
                    multiple
                    name="sub_service_ids"
                    classes={{
                        root: "multi-select-root"
                    }}
                    value={employeeForm.subServiceIds}
                    onChange={(event) => { this.setFieldSubService(event) }}
                    IconComponent={CustomMenuIcon}
                    input={CustomSelectInputProps}
                    MenuProps={CustomSelectMenuProps}
                    renderValue={(selected) => { return renderLabelTags(selected as string[]) }}
                    data-testid="sub-service-field"
                    disabled={!isEditOn}
                >
                    <MenuFont disabled>Sub Service</MenuFont>
                    {subServiceList.map((subService, index) => (
                        <MenuFont key={index} value={subService.sub_service_id.toString()} disableRipple>
                            <CustomCheckbox
                                icon={<img src={unCheckedSM} />}
                                checkedIcon={<img src={checkedSM} />}
                                checked={employeeForm.subServiceIds.indexOf(subService.sub_service_id.toString()) > -1}
                            />
                            {subService.sub_service_name}
                        </MenuFont>
                    ))}
                </MultiSelect>
                <ErrorMsg error>{formErrors.subServiceIds}</ErrorMsg>
            </GridItem>
        )
    }
    renderForm = () => {
        return (
            <>
                <ImageBox>
                    {this.photoField()}
                </ImageBox>
                <Grid container spacing={5}>
                    {this.nameField()}
                    {this.roleField()}
                    {this.emailField()}
                    {this.phoneField()}
                    {this.serviceField()}
                    {this.isShowSubService() ? this.subServiceField(): <></>}
                </Grid>
            </>
        )
    }
    renderAvailability = () => {
        const menuRange = generateTimeRange("00:00", "23:30", 30)
        const { employeeForm, isEditOn, formErrors } = this.state
        return (
            <>
                <AvailabilityWrapper>
                    {employeeForm.availability.map((dayItem, dayIndex) => {
                        const start_time = returnTruthyString(dayItem.start_time)
                        const end_time = returnTruthyString(dayItem.end_time)
                        return (
                            <>
                            {isEditOn || (!isEditOn && dayItem.checked)?
                            <DayBoxWrapper key={dayIndex}>
                                <DayBox>
                                    <DayCheckbox
                                        label={dayItem.day}
                                        checked={dayItem.checked}
                                        labelPlacement="end"
                                        data-testid={`day-checkbox-${dayIndex}`}
                                        control={isEditOn ?
                                            <Checkbox
                                                checkedIcon={<img src={CheckedCheckBox} />}
                                                icon={<img src={UnCheckedCheckBox} />}
                                                onChange={(event) => this.setFieldAvailability(event.target.checked, dayItem.day)}
                                            />
                                            : <></>
                                        }
                                    />
                                </DayBox>

                                <Box>
                                    <TimeBox>
                                        <TimeIcon
                                            className={checkCondition(isEditOn, "edit-time-icon", "default-time-icon") as string}
                                        />
                                        <CustomSelect
                                            className={checkCondition(isEditOn, "edit-select", "default-selects") as string}
                                            id="start-range-day"
                                            variant="outlined"
                                            disabled={!isEditOn}
                                            displayEmpty
                                            MenuProps={CustomSelectMenuProps}
                                            IconComponent={DownArrowIcon}
                                            value={start_time}
                                            onChange={(event) => this.setFieldTimes(event.target.value as string, dayItem.day, "start_time")}
                                            data-testid={`day-start-time-${dayIndex}`}
                                        >
                                            <MenuFont value={""} disabled>00:00</MenuFont>
                                            {menuRange.map((each, index) => {
                                                return (
                                                    <MenuFont value={each.value} key={index}>{each.name}</MenuFont>
                                                )
                                            })}
                                        </CustomSelect>
                                        <CustomSelect
                                            className={checkCondition(isEditOn, "edit-select", "default-selects") as string}
                                            id="end-range-day"
                                            variant="outlined"
                                            disabled={!isEditOn}
                                            displayEmpty
                                            MenuProps={CustomSelectMenuProps}
                                            IconComponent={DownArrowIcon}
                                            value={end_time}
                                            onChange={(event) => this.setFieldTimes(event.target.value as string, dayItem.day, "end_time")}
                                            data-testid={`day-end-time-${dayIndex}`}
                                        >
                                            <MenuFont value={""} disabled>00:00</MenuFont>
                                            {menuRange.map((each, index) => {
                                                return (
                                                    <MenuFont value={each.value} key={index}
                                                        disabled={disableEndTime(index, start_time)}
                                                    >
                                                        {each.name}
                                                    </MenuFont>
                                                )
                                            })}
                                        </CustomSelect>
                                    </TimeBox>
                                    <ErrorMsg error>{formErrors.availability[dayItem.day]}</ErrorMsg>
                                </Box>
                            </DayBoxWrapper>
                            : <></>}
                            </>
                        )
                    })}
                </AvailabilityWrapper>
            </>
        )
    }
    renderFormButtons = () => {
        const { isEditOn } = this.state
        return (
            <ButtonWrapper>
                {isEditOn ?
                    <>
                        <CustomButton
                            style={style.greyBtn}
                            onClick={this.handleCancel}
                            data-testid="cancel-btn"
                        >
                            Cancel
                        </CustomButton>
                        <SaveButton
                            isLoading={this.state.editEmployeeLoading}
                            buttonText="Save"
                            onClick={this.editEmployee}
                            circularProgressStyle={style.smallLoading}
                            data-testid="save-btn"
                        >
                            Save
                        </SaveButton>
                    </>
                    :
                    <CustomButton
                        style={style.blueBtn}
                        onClick={this.handleEdit}
                        data-testid="edit-btn"
                    >
                        Edit
                    </CustomButton>
                }
            </ButtonWrapper>
        )
    }
    renderMessageModal = () => {
        const { openModal, msgModal } = this.state;
        return (
            <>
                {openModal &&
                    <SimpleDialog
                        open={openModal}
                        data-testid="msg-modal"
                        showRightBtn
                        message={msgModal}
                        handleRightClick={this.handleCloseMsgModal}
                        rightBtnTitle="Okay"
                    />
                }
            </>
        )
    }

    renderAddEventModal = () => {
        const { openAddEvent } = this.state
        return (
            <>
                {openAddEvent &&
                    <DialogContainer
                        open={openAddEvent}
                        fullWidth
                        aria-labelledby="add-event-id"
                        data-testid="add-event-modal"
                    >
                        <DialogMainWrapper>
                            <TitleDialog>Add Event</TitleDialog>
                            <InputWrapper>
                                <Box>
                                    <EventFormLabel>Event Name</EventFormLabel>
                                    <CustomField
                                        fullWidth
                                        id="event-name"
                                        value={this.state.eventName}
                                        onChange={(event) => this.setEventName(event.target.value)}
                                        placeholder="Event Name"
                                        data-testid="event-name-input"
                                        className="custom-field"
                                    />
                                </Box>
                                <DateFieldWrapper>
                                    <DateFieldBox>
                                        <EventFormLabel>Start date</EventFormLabel>
                                        <DatePicker
                                            className="custom-day-date-picker"
                                            showIcon
                                            selectsStart
                                            withPortal
                                            toggleCalendarOnIconClick
                                            placeholderText="Choose a Date"
                                            dateFormat="EEE dd/MM/yyyy"
                                            minDate={new Date()}
                                            selected={this.state.eventStartDate}
                                            onChange={(event) => this.setEventDate(event, "eventStartDate")}
                                            icon={<img src={DateCalendar} alt="calendar-icon" />}
                                            data-testid='event-start-date'
                                        />
                                    </DateFieldBox>
                                    <DateFieldBox>
                                        <EventFormLabel>End date</EventFormLabel>
                                        <DatePicker
                                            className="custom-day-date-picker"
                                            showIcon
                                            selectsEnd
                                            withPortal
                                            toggleCalendarOnIconClick
                                            placeholderText="Choose a Date"
                                            dateFormat="EEE dd/MM/yyyy"
                                            minDate={this.state.eventStartDate}
                                            selected={this.state.eventEndDate}
                                            onChange={(event) => this.setEventDate(event, "eventEndDate")}
                                            icon={<img src={DateCalendar} alt="calendar-icon" />}
                                            data-testid='event-end-date'
                                        />
                                    </DateFieldBox>
                                </DateFieldWrapper>
                            </InputWrapper>

                            <DialogButtonWrapper>
                                <DialogButton
                                    style={style.greyBtn}
                                    data-testid="cancel-event-btn"
                                    onClick={this.handleClosAddEvent}
                                >
                                    Cancel
                                </DialogButton>
                                <DialogButton
                                    style={style.blueBtn}
                                    data-testid="save-btn"
                                    onClick={this.addCalendarEvent}
                                >
                                    Save
                                </DialogButton>
                            </DialogButtonWrapper>
                        </DialogMainWrapper>
                    </DialogContainer>
                }
            </>
        )
    }
    renderCalendar = () => {
        return (
            <>
                <EmployeeWrapper>
                    <CalendarBox>
                        <CustomerCalendar
                            calendarDate={new Date()}
                            calendarView={this.state.calendarView}
                            changeView={this.changeCalendarView}
                            data-testid="employee-calendar"
                            events={[]}
                            onEventSelect={(event: Shedule | null) => { }}
                            fetchAppointments={(view: (typeof Views)[keyof typeof Views], date: Date) => { }}
                        />
                    </CalendarBox>
                </EmployeeWrapper>
            </>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <>
                <NavigationMenu id={''} navigation={this.props.navigation} />
                <MainWrapper>
                    <BackBox>
                        <ArrowBtn disableRipple onClick={this.goToListEmployee} data-testid='back-to-list'>
                            <ArrowIcon /><Title>Employee Management</Title>
                        </ArrowBtn>
                    </BackBox>
                    {this.renderEmployeeProfile()}
                    <EmployeeWrapper>
                        <CalendarWrapper>
                            <Typography style={style.calendarTitle}>Employee Calendar</Typography>
                            <AddEventBtn
                                onClick={this.handleOpenAddEvent}
                                data-testid="add-event-btn"
                            >
                                Add Event
                            </AddEventBtn>
                        </CalendarWrapper>
                    </EmployeeWrapper>
                    {this.renderCalendar()}
                    {this.renderAddEventModal()}
                    {this.renderMessageModal()}
                </MainWrapper>
                <AppFooter navigation={this.props.navigation} />
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
    backgroundColor: "#F6F4F9",
    padding: "32px",
    "@media (max-width: 600px)": {
        padding: "32px 16px",
    },
})
const BackBox = styled(Box)({
    margin: "32px",
    "@media (min-width: 600px) and (max-width: 991px)": {
        margin: "32px 16px",
    },
    "@media (max-width: 600px)": {
        margin: "32px 8px",
    },
})
const ArrowBtn = styled(IconButton)({
    padding: 0,
    "&:hover": {
        background: "none !important",
    },
})
const ArrowIcon = styled(KeyboardArrowLeft)({
    color: "#6F05EA",
    width: 48,
    height: 48
})
const Title = styled(Typography)({
    marginLeft: "12px",
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: "30px",
    lineHeight: "20px",
    color: "#0F172A",
    "@media (min-width: 600px) and (max-width: 991px)": {
        fontSize: "22px",
    },
    "@media (max-width: 600px)": {
        fontSize: "16px",
    },
})
const EmployeeWrapper = styled(Box)({
    width: "100%",
    display: "flex",
    justifyContent: "center"
})
const DetailsWrapper = styled(Box)({
    width: "100%",
    maxWidth: "900px",
    marginTop: "32px",
    padding: "61px 36px 61px 36px",
    backgroundColor: "#fff",
    borderRadius: "16px",
    "@media (min-width: 600px) and (max-width: 991px)": {
        padding: "61px 18px 61px 18px",
    },
    "@media (max-width: 600px)": {
        padding: "30px 18px 30px 18px",
    },
})
const ButtonWrapper = styled(Box)({
    padding: "7px 0",
    display: "flex",
    justifyContent: "end",
    columnGap: "26px",
    marginTop: "32px"
})
const CustomButton = styled(Button)({
    padding: "6.5px 0",
    height: "37px",
    width: "136px",
    borderRadius: "31px",
    textAlign: "center",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "24px",
    textTransform: "none",
    fontFamily: 'Montserrat'
})
const SaveButton = styled(LoadingButton)({
    padding: "6.5px 0",
    height: "37px",
    width: "136px",
    borderRadius: "31px",
    textAlign: "center",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "24px",
    textTransform: "none",
    fontFamily: 'Montserrat',
    color: "#6F05EA",
    border: "1px solid #6F05EA",
})
const DayBoxWrapper = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    backgroundColor: "#F8F8F8",
    borderRadius: "4px",
    marginBottom: "10px"
})
const DayBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    height: "60px",
    width: "100px",
    "@media (max-width: 600px)": {
        padding: "0 10px",
    },
})

const TimeBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    height: "60px",
    padding: "0 20px",
    "@media (max-width: 600px)": {
        padding: "0 10px",
    }
})
const DayCheckbox = styled(FormControlLabel)({
    margin: "0px",
    "&.checked-label .MuiFormControlLabel-label": {
        color: "#000000",
    },
    "&.default-label .MuiFormControlLabel-label": {
        color: "#A8A29E",
    },
    "& .MuiFormControlLabel-label": {
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "18px",
        userSelect: "none",
        fontFamily: 'Montserrat'
    },
    "& .Mui-checked + .MuiFormControlLabel-label": {
        color: "#6F05EA",
    },
    "& .MuiCheckbox-root": {
        padding: "0px",
        marginRight: "16px"
    },
})
const CustomSelect = styled(Select)({
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '&.MuiOutlinedInput-root': {
        '& fieldset': {
            border: 'none',
        },
    },
    '& .MuiSelect-select': {
        width: 44,
        backgroundColor: 'transparent',
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: 400
    },
    '& .Mui-disabled': {
        color: "#34424B"
    },
    "&.edit-select .MuiSelect-select": {
        color: '#6F05EA'
    },
    "&.edit-select .MuiSelect-icon": {
        color: '#6F05EA',
    },
});
const DownArrowIcon = styled(KeyboardArrowDown)({
    "& .MuiSelect-icon": {
        color: "#000000",
        marginTop: "-2px"
    }
})
const MenuFont = styled(MenuItem)({
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400
})
const FormWrapper = styled(Box)({
    padding: "0 38px",
    "@media (min-width: 600px) and (max-width: 991px)": {
        padding: "0 19px",
    },
    "@media (max-width: 600px)": {
        padding: "0",
    },
})
const AvailabilityWrapper = styled(Box)({
    marginTop: "54px"
})
const CustomFormLabel = styled(FormLabel)({
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "16px",
    color: "#6F05EA",
    fontFamily: 'Montserrat',
    marginBottom: "4px"
})
const GridItem = styled(Grid)({
    display: "flex",
    flexDirection: "column"
})
const CustomMenuIcon = styled(KeyboardArrowDown)({
    color: '#A6A6A6',
});
const CustomField = styled(TextField)({
    "& .custom-select-root": {
        height: 22,
        backgroundColor: "transparent",
        paddingTop: 8,
        paddingBottom: 4
    },
    "&.custom-field input": {
        height: 22,
        color: "#1E293B",
        paddingTop: 6,
        paddingBottom: 6,
        fontFamily: "Montserrat",
        fontSize: "13px",
        fontWeight: 400,
        "&::placeholder": {
            opacity: 1,
            color: "#A6A6A6",
            fontWeight: 400,
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "Montserrat",
        },
    },

    "&.custom-field .MuiInput-underline:before": {
        borderBottom: "1px solid #D9D9D9",
    },
    "&.custom-field .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "1px solid #D9D9D9",
    },
    "&.custom-field .MuiInput-underline:after": {
        borderBottom: "2px solid #6F05EA",
    },
})
const ImageBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    marginBottom: "54px"
})

const CalendarWrapper = styled(Box)({
    width: "100%",
    maxWidth: "972px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "46px 0 26px 0",
})
const AddEventBtn = styled(Button)({
    padding: "8px 0",
    height: "40px",
    width: "100%",
    maxWidth: "166px",
    borderRadius: "100px",
    textAlign: "center",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "24px",
    textTransform: "none",
    fontFamily: 'Montserrat',
    color: "#6F05EA",
    border: "1px solid #6F05EA",
})

const DialogContainer = styled(Dialog)({
    "& .MuiDialog-container": {
        "& .MuiDialog-paper": {
            maxWidth: 883,
            borderRadius: "16px"
        },
    },
    // GLOBAL CALENDAR
    "& .react-datepicker__input-container": {
        opacity: "1 !important",
        position: "unset",
        "& img": {
            padding: "8px"
        }
    },
    "& .react-datepicker__input-container input": {
        width: "100% ",
        height: "auto !important",
        padding: "8px 10px 10px 30px !important",
        border: "none !important",
        borderBottom: "1px solid #D9D9D9 !important",
        '&:focus': {
            border: "none !important",
            borderBottom: "1px solid #D9D9D9 !important",
            outline: "none !important",
        },
    },
})
const DialogMainWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "61px 36px",
    "@media (max-width: 600px)": {
        padding: "61px 18px",
    },
})
const TitleDialog = styled(Typography)({
    fontWeight: 600,
    fontSize: "27px",
    lineHeight: "24px",
    fontFamily: 'Montserrat',
    color: "#6F05EA"
})
const InputWrapper = styled(Box)({
    margin: "50px 0px",
    width: "100%",
    maxWidth: "360px"
})

const EventFormLabel = styled(Typography)({
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: "13px",
    color: "#6F05EA"
})
const DateFieldWrapper = styled(Box)({
    display: "flex",
    columnGap: "60px",
    rowGap: "24px",
    marginTop: "46px",
    flexWrap: "nowrap",
    "@media (max-width: 600px)": {
        flexWrap: "wrap"
    },
})
const DateFieldBox = styled(Box)({
    minWidth: 160,
    width: "100%",
})
const DialogButtonWrapper = styled(Box)({
    width: "100%",
    justifyContent: "center",
    display: "flex",
    columnGap: "26px"
})
const DialogButton = styled(Button)({
    padding: "16px 0",
    height: "56px",
    width: "100%",
    maxWidth: "180px",
    borderRadius: "31px",
    textAlign: "center",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "24px",
    textTransform: "none",
    fontFamily: 'Montserrat',
    "@media (max-width: 400px)": {
        fontSize: "18px",

    },
})
const CalendarBox = styled(Box)({
    width: "100%",
    maxWidth: "972px",
    backgroundColor: "#FFF"
})

const PlaceHolderFont = styled(Typography)({
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "16px",
    fontFamily: "Montserrat",
    color: "#A6A6A6"
})

const ValueFont = styled(Typography)({
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "16px",
    fontFamily: "Montserrat",
    color: "#1E293B"
})
const MultiSelect = styled(Select)({
    "& .multi-select-root": {
        backgroundColor: "transparent",
    },
    "&:before": {
        borderBottom: "1px solid #D9D9D9 !important",
    },
    "&:hover:not(.Mui-disabled):before": {
        borderBottom: "1px solid #D9D9D9 !important",
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    "& .MuiInput-underline:before": {
        borderBottom: "1px solid #D9D9D9 !important",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "1px solid #D9D9D9 !important",
        borderBottomStyle: "revert"
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '&.MuiOutlinedInput-root': {
        '& fieldset': {
            border: 'none',
        },
    },
    '& .MuiSelect-select': {
        height: 22,
        fontFamily: 'Montserrat',
        backgroundColor: 'transparent !important',
        fontSize: "12px",
        paddingTop: 8,
        paddingBottom: 4
    },
    "& .MuiListItem-button:hover": {
        backgroundColor: "transparent !important"
    },
})


const ImageWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
});
const CustomCheckbox = styled(Checkbox)({
    backgroundColor: "transparent",
    color: "transparent",
    width: 13,
    height: 13,
    "&:hover": {
        backgroundColor: "transparent !important",
    },
    "&.Mui-checked": {
        color: "transparent",
    },
});
const UploadWrapper = styled(Box)({
    display: "flex",
    flexDirection: "row",
});

const PhotoBox = styled(Box)({
    position: "relative",
    height: "83px",
    width: "83px",
    borderRadius: "50%",
})
const ViewProfileAvatar = styled(Avatar)({
    height: "83px",
    width: "83px",
})
const ProfileAvatar = styled(Avatar)({
    height: "83px",
    width: "83px",
    position: "absolute",
    "& .MuiAvatar-img": {
        opacity: 0.8
    },
    "& svg": {
        opacity: 0.2
    }
})
const UploadImg = styled(`img`)({
    position: "relative",
    top: 28,
    left: 28
})
const TimeIcon = styled(AccessTimeIcon)({
    width: 24,
    height: 24,
    color: "#34424B",
    "&.edit-time-icon": {
        color: "#6F05EA",
    }
})
const ErrorMsg = styled(FormHelperText)({
    fontFamily: 'Montserrat',
})
const style = {
    phoneButtonStyle: {
        border: "none",
        background: "transparent",
    } as React.CSSProperties,
    phoneInputStyle: {
        border: "none",
        width: "100%",
        borderBottom: "1px solid #D9D9D9",
        borderRadius: "0px",
        height: "34px",
        paddingLeft: "40px",
        fontWeight: 400,
        fontSize: "13px",
        lineHeight: "16px",
        fontFamily: "Montserrat",
    } as React.CSSProperties,
    phoneDropDownStyle: {
        fontSize: "13px"
    } as React.CSSProperties,
    blueBtn: {
        color: "#6F05EA",
        border: "1px solid #6F05EA",
    } as React.CSSProperties,
    greyBtn: {
        color: "#4B4B4B",
        border: "1px solid #4B4B4B",
    } as React.CSSProperties,
    defaultImgInput: {
        position: 'absolute',
        left: 0,
        top: 0,
        opacity: 0,
        width: '100%',
        height: '100%',
        cursor: 'pointer',
    } as React.CSSProperties,
    calendarTitle: {
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "24px",
        fontFamily: 'Montserrat',
        color: "#000"
    } as React.CSSProperties,
    photoImgInput: {
        position: 'absolute',
        left: 0,
        top: 0,
        opacity: 0,
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        borderRadius: "50%"
    } as React.CSSProperties,
    smallLoading: {
        height: 20,
        width: 20,
        color: "#6F05EA"
    } as React.CSSProperties,
}
// Customizable Area End
