import { Box } from '@material-ui/core';
import React from 'react'
import Field from '../Field';
import { GenericFormikProps } from '../../types';


const TimeChange = ({ event, errors, touched, getFieldProps, setFieldValue, values, renderCustomField, handleBlur }: GenericFormikProps) => {

    return (
        <Box className="form-row">
            <Box style={{ width: '100%', borderBottom: '1px solid #D9D9D9' }}>
                <Field title="Old Time" value={event.shift} withOpacity withPadding />
            </Box>
            <Box style={{ position: "relative", width: '100%' }}>
                {renderCustomField({
                handleBlur,
                errors,
                field: "shift",
                disabledField: "isEditRequestChange",
                values,
                list: [
                    { label: "Morning", value: "Morning" },
                    { label: "Evening", value: "Evening" },
                ],
                touched,
                handleChange: (event: any) => {
                    setFieldValue("shift", event.target.value);
                },
                getFieldProps,
                editLabel: {
                    enabled: true,
                    styles: { fontSize: "12px" },
                },
                })}
            </Box>
        </Box>
    )
}

export default TimeChange;