import React, {
  useEffect,
  useRef,
} from "react";
import { Box, Button, styled } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import Slider, { Settings } from "react-slick";
import {
  backArrowIcon,
  forwardArrowIcon,
} from "../../../blocks/chat/src/assets";
import { BackIcon } from "../photolibrary/assets";
import { transPt } from "../HelperUtils";
type MediaData = {
  image_url: string;
};

type Props = {
  imageVideoData: MediaData[];
  selectedImgVideoId: number | string;
  open: boolean;
  handleImageVideoBack: () => void;
};

const ViewImageSlider = ({
  imageVideoData,
  selectedImgVideoId,
  open,
  handleImageVideoBack,
}: Props) => {
  const initialSlideIndex = imageVideoData.findIndex(
    (item) => item.image_url === selectedImgVideoId
  );

  const sliderRef1 = useRef<Slider>(null);
  const sliderRef2 = useRef<Slider>(null);

  const settings: Settings = {
    infinite: imageVideoData.length >= 5,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: initialSlideIndex >= 0 ? initialSlideIndex : 0,
    nextArrow: imageVideoData.length > 1 ? <img src={forwardArrowIcon} /> : <></>,
    prevArrow: imageVideoData.length > 1 ? <img src={backArrowIcon} /> : <></>,
    asNavFor: sliderRef2.current || undefined,
    afterChange: (current: number) => {
      if (sliderRef2.current) {
        sliderRef2.current.slickGoTo(current);
      }
    },
  };

  const settings2: Settings = {
    infinite: imageVideoData.length >= 5,
    speed: 100,
    centerMode:true,
    slidesToShow: 5,
    slidesToScroll: 1,
    swipeToSlide: true,
    initialSlide: initialSlideIndex >= 0 ? initialSlideIndex : 0,
    focusOnSelect: true,
    asNavFor: sliderRef1.current || undefined,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 2,
        }
      },
    ]
  };

  useEffect(() => {
    if (sliderRef1.current && sliderRef2.current) {
      sliderRef1.current.slickGoTo(initialSlideIndex, true);
      sliderRef2.current.slickGoTo(initialSlideIndex, true);
    }
  }, [initialSlideIndex]);

  const handleClick2 = (index: number) => {
    if (sliderRef1.current) {
      sliderRef1.current.slickGoTo(index);
    }
  };
  return (
    <StyledDialog fullWidth open={open} PaperProps={{ className: 'styled-dialog-container' }}>
      <StyledSliderContainer className="">
        <Slider {...settings} ref={sliderRef1}>
          {imageVideoData.map((item, index: number) => (
            <Box key={index} className="main-slider-box">
                <>
                  <Button
                    startIcon={<img src={BackIcon} alt="back-icon" style={{height:"12px",width:"8px"}} />}
                    className="back-btn"
                    onClick={handleImageVideoBack}
                    data-test-id="view-img-back-btn"
                  >
                    {transPt("Back","scheduling")}
                  </Button>
                  <img src={item.image_url} alt={`card-image-${index}`} />
                </>
            </Box>
          ))}
        </Slider>
        
      </StyledSliderContainer>
      <StyledSecondSlider length={imageVideoData.length}>
        <Slider {...settings2} ref={sliderRef2}>
          {imageVideoData.map((item, index: number) => (
            <Box key={index} onClick={() => handleClick2(index)}>
                <>
                  <img src={item.image_url} alt={`card-image-${index}`} />
                </>
            </Box>
          ))}
        </Slider>
      </StyledSecondSlider>
    </StyledDialog>
  );
};

export default ViewImageSlider;

const StyledSliderContainer = styled(Box)(({ theme }) => ({
  padding:"0px 0px 10px 0px",
  overflowX:"hidden",
  height:"65vh",
   "@media (max-width: 600px)": {
      height:"55vh",
    },
    "@media (max-width: 450px)": {
      height:"45vh",
    },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .styled-dialog-container":{
    maxWidth:"930px",
    backgroundColor:"transparent",
    boxShadow:"none",
    maxHeight:"890px",
    scrollBehavior:"smooth",
    margin:"32px 0px",
    width:"100%",
  },
  "& .slick-prev": {
    zIndex: 1,
    width: "70px",
    height: "70px",
    left:"0px"
  },
  "& .slick-next": {
    width: "70px",
    height: "70px",
    right:"0px"
  },
  "& .slick-slide img": {
    width: "100%",
    height: "100%",
    maxWidth: "940px",
    aspectRatio: 1,
    objectFit:"cover"
  },
  "& .slick-slide video": {
    width: "100%",
    height: "100%",
  },
  "& .slick-dots": {
    height: "40px",
    bottom: "-50px",
    display: "flex !important",
    justifyContent: "space-between",
  },
  "& .slick-dots li": {
    width: "45px",
    height: "100%",
    margin: "0px",
  },
  "& .progress-bar": {
    flexGrow: 1,
    height: "5px",
    backgroundColor: "#ccc",
    cursor: "pointer",
    position: "relative",
    margin: "0 10px",
  },
  "& .progress": {
    height: " 100%",
    backgroundColor: "#fff",
    position: "absolute",
    top: "0",
    left: "0",
    width: "10%",
  },
  "& .video-container": {
    position: "relative",
  },
  "& .play-pause-main-btn": {
    position: "absolute",
    transform: "translate(-50%,-50%)",
    top: "50%",
    left: "50%",
    background: "#00000030",
    borderRadius: "50px",
  },
  "& .play-pause-control-icon": {
    height: "20px !important",
    width: "20px !important",
  },
  "& .controls": {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
    position: "absolute",
    top: "65%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      top: "60%",
    },
  },
  "& .main-image-video": {
    height: "450px",
    width: "450px",
    borderRadius: "0px",
    [theme.breakpoints.down("sm")]: {
      height: "370px",
      width: "370px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "280px",
      width: "280px",
    },
    [theme.breakpoints.down(420)]: {
      height: "240px",
      width: "240px",
    },
  },
  "& .back-btn": {
    border: "1px solid #6F05EA",
    borderRadius: "100px",
    margin: "32px 0px 0px 45px",
    fontSize: "16px",
    fontWeight: 700,
    textTransform: "none",
    color: "#fff",
    backgroundColor: "#6F05EA",
    boxShadow: "none",
    width:"99px",
    position: "absolute",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#6F05EA",
      boxShadow: "none",
    },
    "@media (max-width: 600px)": {
      margin: "22px 0px 0px 35px",
      fontSize: "14px",
    },
    "@media (max-width: 500px)": {
      fontSize: "12px",
    },
    "@media (max-width: 400px)": {
        margin: "15px 0px 0px 25px",
        fontSize: "14px",
      },
  },
  "& .back-btn-video": {
    zIndex: 2,
  },
  "& .arrow-container":{
    height:"70px",
    width:"70px",
    backgroundColor:"#B7B7B7",
    borderRadius:"48px"
  },
  "& .slick-slider":{
    padding:"0 35px"
  },
  "& .slick-list":{
    backgroundColor:"rgb(153, 153, 153)"
  }
}));

const StyledSecondSlider = styled(Box)(
  ({
    length
  }: {
    length: number;
  }) => ({
  "& .slick-prev": {
    display: "none !important",
  },
  "& .slick-next": {
    display: "none !important",
  },
  "& .slick-slider": {
    padding: "0px 32px",
  },
  "& .slick-slide": {
    opacity: 0.75,
    padding: "0px 3px",
  },
  "& .slick-slide>div": {
    height: "100%",
    "& div": {
      height: "100%",
    },
  },
  "& .slick-track": {
    display: "flex",
    transform:length < 5 ? "none !important" : undefined
  },
  "& .slick-slide img": {
    width: "100%",
    height: "100%",
    borderRadius:"8px",
    objectFit:"cover"
  },
  "& .slick-current":{
    opacity:"1 !important",
  },
  "& .slick-current>div":{
    border:"2px solid #6F05EA",
    borderRadius:"8px",
    padding:"2px"
  },
  "& .slick-list":{
    backgroundColor:"rgb(153, 153, 153)"
  }
}));
