import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import {
  createTheme,
  styled,
  ThemeProvider,
} from "@material-ui/core/styles";
import { messageIcon, SearchIcon } from "./assets";
import { AppFooter } from "../../../components/src/AppFooter/appFooter";
import ViewChat from "./ViewChat.web";
//LovePet Chat
import moment from "moment";
import  NavigationMenu  from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import { AppHeader } from "../../../components/src/AppHeader.web";


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
});

const ChatGridContainer = styled(Grid)(({ theme }) => ({
  "& .user-search-container": {
    width: "100%",
    marginTop: "24px",
  },
  "& .user-info-container": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    width: "fit-content",
  },
  "& .user-name": {
    fontSize: "24px",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
  },
  "& .total-msg-badge": {
    borderRadius: "24px",
    padding: "2px 8px 2px 8px",
    backgroundColor: "#6F05EA",
    color: "#fff",
    fontSize: "10px",
    height: "12px",
  },
  "& .user-chat-list": {
    overflowY: "auto",
    marginTop: "32px",
    cursor:'pointer',
    scrollbarWidth:'none' // the scrollbar should none here
  },
  "& .user-chat-list-container": {
    height: "122px",
    display: "flex",
    borderBottom: "1px solid #00000012",
    padding: "0px 16%",
    [theme.breakpoints.down("md")]: {
      padding: "0px 9.5%",
    },
  },
  "& .user-chat-list-container-active": {
    height: "122px",
    display: "flex",
    borderBottom: "1px solid #00000012",
    padding: "0px 16%",
    backgroundColor: "#F0E5FF",
    [theme.breakpoints.down("md")]: {
      padding: "0px 9.5%",
    },
  },
  "& .user-chat-container": {
    display: "flex",
    gap: 10,
    alignItems: "center",
    width: "100%",
  },
  "& .user-chat-img": {
    width: "45px",
    height: "45px",
    borderRadius:'50%',
    [theme.breakpoints.down("md")]: {
      width: "40px",
      height: "40px",
    },
  },
  "& .user-msg-info-container": {
    display: "flex",
    gap: "20px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      gap: "10px",
    },
  },
  "& .user-name-msg": {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  "& .list-user-name": {
    fontSize: "19px",
    fontWeight: 600,
    fontFamily:'Montserrat',
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
  "& .list-user-msg": {
    fontFamily:'Montserrat',
    fontSize: "14px",
    fontWeight: 500,
    color: "#00000099",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  },
  "& .msg-time-container": {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: "auto",
    height: "50px",
  },
  "& .list-msg-time": {
    fontSize: "12px",
    fontWeight: 600,
    color: "#00000099",
  },
  "& .list-msg-badge": {
    borderRadius: "50%",
    backgroundColor: "#6F05EA",
    color: "#fff",
    fontSize: "10px",
    width: '21px',
    height: '21px',
    textAlign: 'center',
    lineHeight: '15px',
    display:'flex',
    alignContent:'center',
    justifyContent:'center'
  },
  "& .view-chat-container": {
    borderLeft: "1px solid #00000012",
    height:"800px",
    overflowY:"auto",
    scrollbarWidth:'none',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  "& .chat-container": {
    borderLeft: "1px solid #00000012",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  "& .display-none": {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  "& .display-block": {
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
}));
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
   formatTimeAgo = (timestamp: string) => {
     if (!timestamp){
      return "";
    }
    const date = moment(timestamp);
    const now = moment();
  
    const minutesDiff = now.diff(date, 'minutes');
    const hoursDiff = now.diff(date, 'hours');
    const today = now.startOf('day');
    const yesterday = moment().subtract(1, 'day').startOf('day');
  
    if (minutesDiff === 0) {
      // If the message is from less than a minute ago, show the current time (e.g., "21:47")
      return date.format('HH:mm');
    } else if (minutesDiff < 60) {
      // If it's less than an hour ago, show minutes (e.g., "12m")
      return `${minutesDiff}m`;
    } else if (date.isSame(today, 'day')) {
      // If it's today, show hours ago (e.g., "3h")
      return `${hoursDiff}h`;
    } else if (date.isSame(yesterday, 'day')) {
      // If it's yesterday, show "Yesterday"
      return "Yesterday";
    } else {
      // For dates older than yesterday, show as "24/10/24"
      return date.format('DD/MM/YY');
    }
};

  renderDefaultSection = () => {
    return (
      <DefaultBox>
        <DefaultImgBox>
          <img src={messageIcon} />
        </DefaultImgBox>
        <DefaultTextBox>
          <DefaultTypoNothing className="nothing-here">Nothing here</DefaultTypoNothing>
          <DefaultTypoNoChat className="no-chat">There are no chats in your feed.</DefaultTypoNoChat>
        </DefaultTextBox>
      </DefaultBox>
    )
  }

  renderLoader = () => {
    return (
      <Box style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <CustomLoader />
      </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const isVendor = localStorage.getItem("login_user_role") === "vendor";
  
    const { filteredChatList, currentUserName, currentUserProfile, isLoading, totalUnreadMessages } = this.state;
    return (
      <ThemeProvider theme={theme}>
        {isVendor && <NavigationMenu id={''} navigation={this.props.navigation} handleCustomerHeaderSelect={this.handleCustomerHeaderSelect} isChat={true} isProfile={false} data-test-id="header" onLogoClick={this.openListOfEstablishment}/>}
       {!isVendor && <AppHeader navigation={this.props.navigation} handleCustomerHeaderSelect={this.handleCustomerHeaderSelect} isProfile={false}/>
} 
        {filteredChatList.length === 0 && !isLoading && this.renderDefaultSection()}
        {filteredChatList.length === 0 && isLoading && this.renderLoader()}
        {filteredChatList.length > 0 && !isLoading && <ChatGridContainer container>
          <Grid
            item
            md={4}
            lg={3}
            className={`${
              this.state.isChatListShow ? "display-block" : "display-none"
            } view-chat-container`}
          >
            <Box className="user-search-container">
              <Box style={{ marginLeft: "16%" }}>
                <Box className="user-info-container">
                  <img src={currentUserProfile} alt="userImg" className="user-chat-img" />
                  <Typography className="user-name">{currentUserName}</Typography>
                  {totalUnreadMessages > 0 && <Box className="total-msg-badge">{totalUnreadMessages }</Box>}
                </Box>
                <TextField
                  value={this.state.searchQuery}
                  name="search"
                  placeholder="Search"
                  style={{ marginTop: "10px" }}
                  data-test-id="search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={SearchIcon} alt="search-icon" />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  onChange={this.handleSearch}
                />
              </Box>
            </Box>

            <Box className="user-chat-list">
              {filteredChatList && filteredChatList.length > 0 ? (
                filteredChatList.map((message) => (
                  <Box
                    key={message.chat_room.id}
                    className={`${
                      this.state.chatUserId === message.chat_room.id
                        ? "user-chat-list-container-active"
                        : "user-chat-list-container"
                    }`}
                    onClick={() => this.handleChatListUserClick(message)}
                    data-test-id="user-chat-list"
                  >
                    <Box className="user-chat-container">
                      <img src={message.other_user_data.image_url} className="user-chat-img" />
                      <Box className="user-msg-info-container">
                        <Box className="user-name-msg">
                          <Typography className="list-user-name">
                            {message.other_user_data.name}
                          </Typography>
                          <Typography className="list-user-msg">
                            {message.chat_room.last_message}
                          </Typography>
                        </Box>
                        <Box className="msg-time-container">
                          <Typography className="list-msg-time" data-test-id='formatTime'>
                            {this.formatTimeAgo(message.chat_room.last_message_time)}
                          </Typography>
                          {message.chat_room.unread_messages > 0 && (
                            <Box data-test-id='unreadcount' className="list-msg-badge">
                              <Typography style={{marginTop:2,color:'#fff',fontSize:10,fontWeight:'bold',alignSelf:'center'}}>{message.chat_room.unread_messages}</Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))
              ) : (
                <Typography variant="body1" className="empty-chat-message">
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid
            item
            md={8}
            lg={9}
            className={`${
              this.state.isChatListShow ? "display-none" : "display-block"
            } chat-container`}
          >
            {isLoading ? (
              <Box className="empty-chat-placeholder">
                <Typography variant="body1" color="textSecondary" style={{display: 'none'}}>
                  Loading conversation...
                </Typography>
              </Box>
            ) : (
              <ViewChat
                handleBackListClick={this.handleClickBackChatList}
                userId={this.state.chatUserId}
                navigation=""
                id="chat-view"
                userListData={this.state.UserChatList}
                conversationId={this.state.conversationSID}
                userAccessToken={this.state.accessTokenid}
              />
            )}
          </Grid>
        </ChatGridContainer>
  }
        <Box style={{marginTop:-50}}>
        <AppFooter navigation={this.props.navigation} />
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const CustomLoader = styled(CircularProgress)({
  color: "#6F05EA"
})

const DefaultBox = styled(Box)({
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: 12,
  margin: "25px 0px"
})

const DefaultTextBox = styled(Box)({
  display: "flex",
  gap: 18,
  flexDirection: "column"
})

const DefaultImgBox = styled(Box)({
  "& img": {
    width: 89,
    height: 89,
    "@media (max-width: 960px)": {
      width: 79,
      height: 79,
    },
    "@media (max-width: 600px)": {
      width: 69,
      height: 69,
    },
    "@media (max-width: 450px)": {
      width: 50,
      height: 50,
    },
  }
})

const DefaultTypoNothing = styled(Typography)({
  color: "#B7B7B7",
  textAlign: "center",
  lineHeight: "22px",
  fontWeight: 500,
  fontSize: 40,
  "@media (max-width: 960px)": {
    fontSize: 38,
  },
  "@media (max-width: 600px)": {
    fontSize: 36,
  },
  "@media (max-width: 450px)": {
    fontSize: 34,
  },
})

const DefaultTypoNoChat = styled(Typography)({
  textAlign: "center",
  fontWeight: 500,
  color: "#B7B7B7",
  lineHeight: "22px",
  fontSize: "20px",
  "@media (max-width: 960px)": {
    fontSize: 19,
  },
  "@media (max-width: 600px)": {
    fontSize: 18,
  },
  "@media (max-width: 450px)": {
    fontSize: 16,
  },
})
// Customizable Area End
