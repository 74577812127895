import { Box, IconButton, Paper, Typography, styled } from "@material-ui/core";
import {
  UserIcon,
  backArrowIcon,
  closeImageIcon,
  forwardArrowIcon,
  pauseIcon,
  volumeIcon,
  playIcon,
  volumeOffIcon,
} from "../../../packages/blocks/chat/src/assets";
import React, { ReactNode, useEffect, useRef, useState } from "react";
export const closeIcon = require("../src/close-icon.svg");
interface ViewImageDialogModel {
  isImageDialogOpen: boolean;
  viewImageArray: string[];
  imageIndex: number;
  type: string;
  timestamp: string;
}
interface ModalProps {
  open: boolean;
  children?: ReactNode;
  userName: string;
  userProfile:string;
  viewImageDialog: ViewImageDialogModel;
  handleDialogClose: () => void;
  handlePrevClick: () => void;
  handleNextClick: () => void;
}
const DialogContainer = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 999,
  bgcolor: "rgba(255, 255, 255, 0.94)",
  "& .dialog-paper": {
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.94)",
  },
  "& .dialog-header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: "10px 20px",
  },
  "& .header-info": {
    gap: "10px",
    display: "flex",
    alignItems: "center",
  },
  "& .header-username": {
    fontWeight: "600",
    fontSize: "24px",
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "14px",
    },
  },
  "& .header-name-time": {
    display: "flex",
    flexDirection: "column",
  },
  "& .time-stamp": {
    fontSize: "12px",
    color: "#00000075",
  },
  "& .img-container": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100% - 52px)",
    position: "relative",
  },
  "& .img-container-box": {
    display: "flex",
    alignItems: "center",
  },
  "& .arrow-icon": {
    height: "80px",
    width: "80px",
    [theme.breakpoints.down("sm")]: {
      height: "60px",
      width: "60px",
    },
  },
  "& .main-image-video": {
    height: "450px",
    width: "450px",
    borderRadius: "0px",
    [theme.breakpoints.down("sm")]: {
      height: "370px",
      width: "370px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "280px",
      width: "280px",
    },
    [theme.breakpoints.down(420)]: {
      height: "240px",
      width: "240px",
    },
  },
  "& .header-user-img": {
    width: "48px",
    height: "48px",
    borderRadius:'100%',
    [theme.breakpoints.down("md")]: {
      width: "42px",
      height: "42px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "38px",
      height: "38px",
    },
    [theme.breakpoints.down(400)]: {
      width: "34px",
      height: "34px",
    },
  },
  "& .controls": {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
    position: "absolute",
    top: "65%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
        top: "60%",
    },
  },
  "& .progress-bar": {
    flexGrow: 1,
    height: "5px",
    backgroundColor: "#ccc",
    cursor: "pointer",
    position: "relative",
    margin: "0 10px",
  },
  "& .progress": {
    height: " 100%",
    backgroundColor: "#fff",
    position: "absolute",
    top: "0",
    left: "0",
    width: "10%",
  },
  "& .video-container": {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .play-pause-main-btn": {
    position: "absolute",
    transform: "translate(-50%,-50%)",
    top: "50%",
    left: "50%",
    background: "#00000030",
    borderRadius: "50px",
  },
  "& .play-pause-control-icon": {
    height: "20px",
    width: "20px",
  },
}));

const CustomViewImage: React.FC<ModalProps> = ({
  open,
  userName,
  userProfile,
  viewImageDialog,
  handleDialogClose,
  handlePrevClick,
  handleNextClick,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = () => {
    const video = videoRef.current;
    console.log("chat",video);
    
    if (video) {
      const progress = (video.currentTime / video.duration) * 100;
      setProgress(progress);
    }
  };

  const handleProgressClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const video = videoRef.current;
    const progressBar = event.currentTarget;
    const clickPosition = event.nativeEvent.offsetX;
    const progressBarWidth = progressBar.clientWidth;
    const clickRatio = clickPosition / progressBarWidth;

    if (video) {
      video.currentTime = clickRatio * video.duration;
    }
  };
  const handleVideoVolume = () => {
    setIsMuted(!isMuted);
  };

  const isArray = Array.isArray(viewImageDialog.viewImageArray);
  const images = isArray
    ? viewImageDialog.viewImageArray[viewImageDialog.imageIndex]
    : viewImageDialog.viewImageArray;
  useEffect(() => {
    const rootElement = document.getElementById("root");
    if (rootElement) {
      if (open) {
        rootElement.style.overflow = "hidden";
      } else {
        rootElement.style.overflow = "auto";
      }
    }
  }, [open]);
  return (
    <Box>
      {open && (
        <DialogContainer>
          <Paper className="dialog-paper">
            <Box className="dialog-header">
              <Box className="header-info">
                <img
                  className="header-user-img"
                  src={userProfile}
                  alt="user-icon"
                />
                <Box className="header-name-time">
                  <Typography className="header-username">
                    {userName}
                  </Typography>
                  <Typography className="time-stamp">
                    {viewImageDialog.timestamp}
                  </Typography>
                </Box>
              </Box>
              <IconButton
                aria-label="close"
                size="small"
                onClick={handleDialogClose}
                data-test-id="img-dialog-close"
              >
                <img
                  className="close-icon"
                  src={closeImageIcon}
                  style={{ height: "27px" }}
                  alt="close-icon"
                />
              </IconButton>
            </Box>
            <Box className="img-container">
              <Box className="img-container-box">
                {images.length >1 && (
                  <IconButton className="back-arrow">
                  <img
                    src={backArrowIcon}
                    onClick={handlePrevClick}
                    className="arrow-icon"
                    data-test-id="img-dialog-prev-arrow"
                  />
                </IconButton>
                )}
                {viewImageDialog.type === "image" ? (
                  <img
                    className="main-image-video"
                    src={images as string}
                    alt="image"
                    data-test-id="model-image"
                    style={{objectFit: "cover"}}
                  />
                ) : (
                  <Box className="video-container">
                    <video
                      className="main-image-video"
                      ref={videoRef}
                      src={images as string}
                      muted={isMuted}
                      onTimeUpdate={handleTimeUpdate}
                      data-test-id="model-video"
                    ></video>
                    <IconButton
                      onClick={handlePlayPause}
                      className="play-pause-main-btn"
                    >
                      <img
                        className="play-pause-main-icon"
                        src={isPlaying ? pauseIcon : playIcon}
                        alt="close-icon"
                      />
                    </IconButton>
                    <div className="controls">
                      <IconButton onClick={handlePlayPause} className="">
                        <img
                          className="play-pause-control-icon"
                          src={isPlaying ? pauseIcon : playIcon}
                          alt="close-icon"
                        />
                      </IconButton>
                      <div
                        className="progress-bar"
                        onClick={handleProgressClick}
                      >
                        <div
                          className="progress"
                          style={{ width: `${progress}%` }}
                        ></div>
                      </div>
                      <IconButton onClick={handleVideoVolume}>
                        <img
                          className="play-pause-control-icon"
                          src={isMuted ? volumeOffIcon : volumeIcon}
                          alt="close-icon"
                        />
                      </IconButton>
                    </div>
                  </Box>
                )}

                <IconButton className="forward-arrow">
                  <img
                    src={forwardArrowIcon}
                    onClick={handleNextClick}
                    className="arrow-icon"
                    data-test-id="img-dialog-next-arrow"
                  />
                </IconButton>
              </Box>
            </Box>
          </Paper>
        </DialogContainer>
      )}
    </Box>
  );
};

export default CustomViewImage;
