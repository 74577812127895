import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
const configJSON = require("../config");
import { capitalizeFirstLetter, reorderArrayByTitles, returnTruthyString } from "../../../../components/src/HelperUtils";
import ServiceGalleryWeb from "./ServiceGallery.web";
// Customizable Area End

import ServiceBookingTableWebController, {
  Props,
} from "./ServiceBookingTableWebController";
// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          fontFamily: "Montserrat",
        },
      },
    },
  },
});
// Customizable Area End

export default class ServiceBookingTableWeb extends ServiceBookingTableWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderTableHeader = () => {
    const recurrenceServiceTypes = [configJSON.SERVICES_ENUM.DAY_CARE, configJSON.SERVICES_ENUM.DOG_WALKING];
    const isRecurrenceShow = recurrenceServiceTypes.includes(this.props.serviceType);
    return (
      <TableHead className="table-head">
        <TableRow>
          <TableCell style={{ padding: 0, borderBottom: "none" }}>
            <div className="boxed-head-div">
              <TableCell className="boxed-cell serviceTableCell">
                <Box className="mainTableHead">
                  <Typography
                    className="tableHead"
                    data-test-id="service-header-title"
                  >
                    {this.getServiceTypeHeaderName()}
                  </Typography>{" "}
                </Box>
              </TableCell>
              {isRecurrenceShow && (
                <TableCell className="boxed-cell recurrenceTableCell" style={{ minWidth: "97px", padding:"0px 16px" }}>
                  <Box className="mainTableHead">
                    <Typography className="tableHead">
                      {this.transTable("Recurrence")}
                    </Typography>{" "}
                  </Box>
                </TableCell>
              )}
              <TableCell className="boxed-cell capacityTableCell">
                <Box className="mainTableHead">
                  <Typography className="tableHead">{this.transTable("Capacity")}</Typography>{" "}
                </Box>
              </TableCell>
              <TableCell className="boxed-cell" style={{ minWidth: "59px", padding:"0px 16px" }}>
                <Box className="mainTableHead">
                  <Typography className="tableHead">{this.transTable("Price")}</Typography>{" "}
                </Box>
              </TableCell>
              <TableCell
                className="boxed-cell"
                style={{ minWidth: "128px" }}
              ></TableCell>
            </div>
          </TableCell>
        </TableRow>
      </TableHead>
    )
  }

  // Customizable Area End
  render() {
    // Customizable Area Start
    const recurrenceServiceTypes = [configJSON.SERVICES_ENUM.DAY_CARE, configJSON.SERVICES_ENUM.DOG_WALKING];
    const isRecurrenceShow = recurrenceServiceTypes.includes(this.props.serviceType);

    const hasEmptyPrices = this.props.data.some(subService => 
      Array.isArray(subService.prices) && subService.prices.length === 0
    );

    const {isSubServiceGalleryOpen,subServiceImageList} = this.state;
    const  convertedImageList = subServiceImageList.length > 0 ? subServiceImageList.map(({ url }) => ({ image_url:url })) : [];
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <StyledTestTable data-test-id="table-container">
          <Table className="table">
            {this.renderTableHeader()}
            <TableBody className="table-body">
              {this.props.data.length > 0 && !hasEmptyPrices && this.props.data.map((subService) => {

                const title = returnTruthyString(capitalizeFirstLetter(subService.title));
                const capacity = returnTruthyString(subService.capacity);
                const description = returnTruthyString(subService.description);

                // REMOVE COMPLEMENTARY RECORDS
                const filteredPrices = subService.prices.filter((priceRecord) => !configJSON.COMPLEMENTARY_SERVICE.includes(priceRecord.title))

                // FILTER RECURRENCE RECORDS
                const filteredRecurrence = subService.prices.filter((priceRecord) => configJSON.RECURRENCE_KEY.includes(priceRecord.title)).reverse();
                const recurrenceList = reorderArrayByTitles(filteredRecurrence, configJSON.RECURRENCE_KEY)

                const selectedRecurrencePrice = filteredPrices.find((price) => price.id == +(this.state.recurrenceStates[subService.id]));
                const isRecurrence = isRecurrenceShow && selectedRecurrencePrice 

                const recurrencePrice = isRecurrence ? selectedRecurrencePrice?.price.toFixed(2) : "0" // SELECTED RECURRENCE PRICE
                const subServicePrice = isRecurrenceShow ? recurrencePrice : filteredPrices[0].price.toFixed(2) // CHECK AND RENDER FINAL PRICE
                
                const SelectedPriceRecord = isRecurrence ? selectedRecurrencePrice : filteredPrices[0]  // FETCH SELECTED RECURRENCE PRICE RECORD
                const subServiceId = subService.id
                const recurrenceDefaultValue = recurrenceList.find((recurrenceType) => recurrenceType.title === "Once a week");
                                
                return (
                  <TableRow key={subService.id}>
                    <TableCell style={{ padding: 0 }}>
                      <div className="boxed-div">
                        <TableCell data-test-id="sub-service-name" onClick={() => this.handleGalleyShow(returnTruthyString(capacity),returnTruthyString(subServicePrice),returnTruthyString(description),subServiceId)} style={{cursor:"pointer"}} className="boxed-cell serviceTableCell">
                          <span title={title} className="serviceBodyText">
                            {title}
                          </span>
                        </TableCell>
                        {isRecurrenceShow && (
                          <TableCell className="boxed-cell recurrenceTableCell">
                            <StyledSelect
                              id="recurrence-id"
                              defaultValue={recurrenceDefaultValue?.id}
                              value={this.state.recurrenceStates[subService.id] || recurrenceDefaultValue?.id}
                              displayEmpty
                              IconComponent={KeyboardArrowDown}
                              onChange={(event) =>
                                this.handleRecurrenceChange(
                                  event.target.value as string,
                                  subService.id,
                                )
                              }
                              data-test-id="recurrence-id"
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "center",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "center",
                                },
                                getContentAnchorEl: null,
                                PaperProps: {
                                  style: {
                                    maxHeight: 155,
                                    borderRadius: "8px",
                                  },
                                },
                                MenuListProps: {
                                  style: {
                                    maxHeight: 155,
                                    overflowY: "auto",
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                  },
                                },
                              }}
                            >
                             {recurrenceList 
                                  .map((option) => (
                                    <StyledMenuItem
                                      value={option.id}
                                      key={option.id}
                                    >
                                      {this.transTable(option.title)}
                                    </StyledMenuItem>
                                  ))
                              }
                            </StyledSelect>
                          </TableCell>
                        )}
                        <TableCell className="boxed-cell capacityTableCell bodyText">
                          {capacity}
                        </TableCell>
                        <TableCell className="boxed-cell priceTableCell bodyText">
                        € {subServicePrice}
                        </TableCell>
                        <TableCell className="boxed-cell">
                          <Button
                            className={this.getButtonClass(subService.id)}
                            data-test-id="select-btn"
                            disabled={this.getButtonClass(subService.id).includes(
                              "notSelectBtn"
                            )}
                            onClick={() => this.handleSelectClick(subService.id,SelectedPriceRecord)}
                          >
                            {this.getButtonName(subService.id)}
                          </Button>
                        </TableCell>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
              {this.props.data.length > 0 && hasEmptyPrices && (
                <Box className="no-sub-service">{this.transTable("No Sub Service Available")}</Box>
              )}
            </TableBody>
          </Table>
        </StyledTestTable>
        {isSubServiceGalleryOpen && subServiceImageList ? 
          (<ServiceGalleryWeb isGalleryLoading={this.state.isGalleryLoading} handelCloseClick={() => this.handleCloseViewMore()} galleryData={convertedImageList} isSubService={true} data-test-id="service-gallery" subServiceData={this.state.subServiceGalleryData} />)
          : (<></>)
        }
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledTestTable = styled(Paper)(({ theme }) => ({
  borderRadius: 10,
  marginBottom:"80px",
  overflow: "auto",
  boxShadow: "none",
  "& .MuiTable-root": {
    minWidth: 650,
  },
  "& .MuiTableHead-root": {
    backgroundColor: "#3f51b5",
  },
  "& .MuiTableRow-root": {
    "& .MuiTableCell-root": {
      borderBottom: "none",
    },
  },
  "& .boxed-head-div": {
    borderRadius: 10,
    display: "flex",
    overflow: "hidden",
    margin: "10px 0px",
    height: "37px",
    backgroundColor: "#F6F6F6",
    "& .boxed-cell": {
      flex: 1,
      borderBottom: "none",
    },
  },
  "& .table-btn": {
    borderRadius: "100px",
    fontSize: "13px",
    boxShadow: "none",
    fontWeight: 700,
    textTransform: "none",
    minWidth: "128px !important",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  "& .select-btn": {
    border: "1px solid #6F05EA",
    color: "#fff !important",
    backgroundColor: "#6F05EA !important",
    "&:hover": {
      color: "#fff",
    },
  },
  "& .purple-btn": {
    border: "1px solid #6F05EA",
    color: "#6F05EA",
    backgroundColor: "transparent",
    "&:hover": {
      color: "#6F05EA",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  "& .notSelectBtn": {
    border: "1px solid grey",
    color: "grey",
    backgroundColor: "transparent",
    "&:hover": {
      color: "grey",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  "& .boxed-div": {
    borderRadius: 10,
    boxShadow: "0px 0px 5.5px 0px #0000001A",
    display: "flex",
    overflow: "hidden",
    margin: "10px 0px",
    height: "67px",
    alignItems: "center",
    "& .boxed-cell": {
      flex: 1,
      borderBottom: "none",
      padding:"16px 12px 16px 12px",
    },
  },
  "& .MuiTableCell-head": {
    color: "#ffffff",
    fontWeight: 600,
    fontSize: 16,
  },
  "& .mainTableHead": {
    display: "flex",
    gap: "20px",
    width: "inherit",
    alignItems: "center",
    height: "100%",
    "& .tableHead": {
      fontSize: "16px",
      fontWeight: 500,
      fontFamily: "Montserrat",
    },
  },
  "& .table-body > tr > td": {
    borderBottom: "none",
  },
  "& .priceTableCell": {
    minWidth: "56px",
  },
  "& .capacityTableCell": {
    minWidth: "75px",
    display:"flex",
    justifyContent:"center",
    padding:"0px 16px"
  },
  "& .serviceTableCell": {
    minWidth: "103px",
    padding:"0px 16px 0px 15px"
  },
  "& .serviceBodyText": {
    color: "#6F05EA",
    fontSize: "14px",
    overflow: "hidden",
    fontWeight: 600,
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
  "& .bodyText": {
    fontSize: "14px",
    fontWeight: 600,
  },
  "& .recurrenceTableCell": {
    minWidth: "119px",
    fontSize:"14px"
  },
  "& .no-sub-service":{
    textAlign:"center",
    color:"#A6A6A6",
    fontSize:"16px",
    fontWeight:400,
    lineHeight:"27px",
    fontFamily:"Montserrat",
    marginTop:"40px"
  },
}));
const StyledSelect = styled(Select)({
  backgroundColor: "#F0E5FF",
  borderRadius: "8px",
  width: "100%",
  color: "#6200EA",
  fontSize: "14px",
  fontWeight: 400,
  paddingLeft: "8px",
  "& svg": {
    color: "#6200EA",
  },
  "&:before": {
    borderBottom: "none",
  },
  "&:after": {
    borderBottom: "none",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&:hover:not(.Mui-disabled):before": {
    borderBottom: "none",
  },
  "& div": {
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
});
const StyledMenuItem = styled(MenuItem)({
  fontSize: "12px",
  color: "#0F172A",
  fontWeight: 600,
  height: "37px",
  "&:hover": {
    backgroundColor: "#F3EBFF",
  },
  "&.Mui-selected": {
    backgroundColor: "#F3EBFF !important",
  },
});
// Customizable Area End
