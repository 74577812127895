Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";

exports.btnExampleTitle = "CLICK ME";


exports.filterMenuList = [
  {
    label: "Today",
    value: "Today"
  },
  {
    label: "This Week",
    value: "This Week"
  },
  {
    label: "This Month",
    value: "This Month"
  },
  {
    label: "This Year",
    value: "This Year"
  },
  {
    label: "Custom Date Range",
    value: "Custom Date Range"
  }
]
exports.chartLabels = [
  {
    label: "Day walking",
    value: "30%",
    color: "#6200EA"
  },
  {
    label: "Individual walking",
    value: "20%",
    color: "#EC4899"
  },
  {
    label: "Group walking",
    value: "20%",
    color: "#60A5FA"
  },
  {
    label: "Individual walking 2",
    value: "20%",
    color: "#F59E0B"
  },
  {
    label: "Group walking 2",
    value: "10%",
    color: "#2563EB"
  }
]
// Customizable Area End