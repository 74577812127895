import { enLandingPage, ptLandingPage } from "./translations/customerLandingPage";
import { enCustomerSignUp, ptCustomerSignUp } from "./translations/customerSignUp";
import { enForgotNewPassword, ptForgotNewPassword } from "./translations/forgotNewPassword";
import { enLogin, ptLogin } from "./translations/login";
import { enScheduling, ptScheduling } from "./translations/scheduling";
import { enVendorSignUp, ptVendorSignUp } from "./translations/vendorSignUp";

export const availableResources = {
    en: {
        login: enLogin,
        customerSignUp: enCustomerSignUp,
        forgotNewPassword:enForgotNewPassword,
        vendorSignUp: enVendorSignUp,
        landingPage: enLandingPage,
        scheduling:enScheduling
    },
    pt: {
        login: ptLogin,
        customerSignUp: ptCustomerSignUp,
        forgotNewPassword: ptForgotNewPassword,
        vendorSignUp: ptVendorSignUp,
        landingPage:ptLandingPage,
        scheduling:ptScheduling
    }
}
