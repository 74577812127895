import { Box, FormHelperText, TextField, Typography } from '@material-ui/core';
import React from 'react'
import { GenericFormikProps } from '../../types';
import StartDateChange from './StartDateChange';
import TimeChange from './TimeChange';
import EndDateChange from './EndDateChange';
import RecurrenceChange from './RecurrenceChange';
import DayOfWeekChange from './DayOfWeekChange';

const styles = {
    box: {display: 'flex', flexDirection: 'row', gap: '4px', alignItems: 'center'},
    text: {fontSize: '12px', fontFamily: 'Montserrat', fontHeight: 400, color: '#6F05EA'},
    input: {width: '48px', textAlign: 'center', fontWeight: 500, fontFamily: 'Montserrat', fontSize: '18px'}
}

const GroomingContent = (props: GenericFormikProps) => {
    const { event, isAll, isReccurence, values, setFieldValue, errors, touched } = props;
    const isGrooming = event.service.service_type === "Grooming";
    const isSelectedAll = isAll && isReccurence;

    if(!isGrooming) {
        return null;
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value.replace(/\D/g, '');
        setFieldValue('repeatEvery', value);
    }

   return (
    <Box className="form-wrapper">
        <StartDateChange {...props} isReccurence={isSelectedAll} />
        {isSelectedAll && <EndDateChange {...props} />}
        <TimeChange {...props} />
        {isSelectedAll && <RecurrenceChange {...props} />}
        {isSelectedAll && <DayOfWeekChange {...props} />}
        {isSelectedAll && 
            <>
                <Box style={styles.box as React.CSSProperties}>
                <Typography style={styles.text}>Repeat every</Typography>
                <TextField 
                    value={values.repeatEvery} 
                    inputProps={{ style: styles.input as React.CSSProperties}} 
                    onChange={(event) => handleChange(event)} 
                />
                <Typography style={styles.text}>weeks</Typography>
                </Box>
                {errors.repeatEvery && touched.repeatEvery && <FormHelperText style={{ color: '#f44336'}}>{errors.repeatEvery}</FormHelperText>}
            </>
        }
        
        
    </Box>
   ) 
}

export default GroomingContent;