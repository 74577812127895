import { Box } from '@material-ui/core';
import React from 'react'
import { GenericFormikProps } from '../../types';
import DaySelect from './DaySelect';


const DayOfWeekChange = (props: GenericFormikProps) => {

    return (
        <Box className="form-row">
            <Box style={{ width: '100%' }}>
                <DaySelect {...props} isDisabled={true} label='Old Day of the Week' />
            </Box>
            <Box style={{ width: '100%' }}>
                <DaySelect {...props} isDisabled={false} isDisplay label='Updated Day of the Week' />
            </Box>            
    </Box>
    )
}

export default DayOfWeekChange;