// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { isValidEmail, returnTruthyString } from "../../../../components/src/HelperUtils";
import { IUserRole, IServiceSubService, ISubService } from "../../../../components/src/interfaces.web";
export const configJSON = require("../config.js");
type DayKey = "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
interface IAvailability {
    day: DayKey,
    start_time: string,
    end_time: string,
    checked: boolean
}

interface IEmployeeForm {
    photo: {
        file: File | null,
        url: string,
    };
    name: string;
    roleId: string;
    email: string;
    phone: string;
    serviceId: string;
    subServiceIds: string[];
    availability: IAvailability[]
}
const initialFormValues: IEmployeeForm = {
    photo: {
        file: null,
        url: "",
    },
    name: "",
    roleId: "",
    email: "",
    phone: "",
    serviceId: "",
    subServiceIds: [],
    availability: configJSON.initialAvailability as IAvailability[]
}
interface IEmployeeFormError {
    photo: string;
    name: string;
    roleId: string;
    email: string;
    phone: string;
    serviceId: string;
    subServiceIds: string;
    availability: Record<DayKey, string>
}

const initialErrors: IEmployeeFormError = {
    photo: "",
    name: "",
    roleId: "",
    email: "",
    phone: "",
    serviceId: "",
    subServiceIds: "",
    availability: {
        Monday: "",
        Tuesday: "",
        Wednesday: "",
        Thursday: "",
        Friday: "",
        Saturday: "",
        Sunday: ""
    }
}
type IAddEmployeeResponse = {
    id?: string;
    errors?: string[];
};
// Customizable Area End


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    handleCloseAddEmployee: () => void
    getEmployees: () => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    apiToken: string,
    establishmentId: string,
    formNumber: number,
    // FORM
    employeeForm: IEmployeeForm,
    formErrors: IEmployeeFormError,
    modalOpen: boolean,
    modalMsg: string,
    // API
    userRoleList: IUserRole[],
    userRoleListLoading: boolean,
    serviceList: IServiceSubService[],
    serviceListLoading: boolean,
    subServiceList: ISubService[]
    addEmployeeLoading: boolean,
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class AddEmployeeController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getServicesListApiCallId: string = ""
    getUserRolesApiCallId: string = ""
    addEmployeeApiCallId: string = ""
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];

        this.state = {
            apiToken: returnTruthyString(localStorage.getItem("login_token")),
            establishmentId: returnTruthyString(localStorage.getItem("establishment_ID")),
            formNumber: 1,
            // FORM
            employeeForm: initialFormValues,
            formErrors: { ...initialErrors },
            modalOpen: false,
            modalMsg: "",
            // API
            userRoleList: [],
            userRoleListLoading: false,
            serviceList: [],
            serviceListLoading: false,
            subServiceList: [],
            addEmployeeLoading: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId) {
                if (apiRequestCallId == this.getUserRolesApiCallId) {
                    return this.getUserRolesResp(responseJson)
                }
                if (apiRequestCallId == this.getServicesListApiCallId) {
                    return this.getServicesListResp(responseJson)
                }
                if (apiRequestCallId == this.addEmployeeApiCallId) {
                    return this.createEmployeeApiResp(responseJson)
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    getUserRolesResp = (responseJson: IUserRole[]) => {
        this.setState({ userRoleListLoading: false })
        if (responseJson && responseJson.length) {
            this.setState({
                userRoleList: responseJson
            })
        } else {
            this.setState({
                userRoleList: []
            })
        }
    }
    getServicesListResp = (responseJson: IServiceSubService[]) => {
        this.setState({ serviceListLoading: false })
        if (responseJson && responseJson.length) {
            this.setState({
                serviceList: responseJson,
                subServiceList: [],
            })
        } else {
            this.setState({
                serviceList: [],
                subServiceList: [],
            })
        }
    }
    createEmployeeApiResp = (responseJson: IAddEmployeeResponse) => {
        this.setState({ addEmployeeLoading: false })
        if (responseJson && 'id' in responseJson) {
            this.handleClose()
            this.props.getEmployees()
        }
        if (responseJson?.errors && responseJson?.errors.length) {
            const firstError = responseJson?.errors[0]
            this.setMsgModal(true, firstError)
        }
    }
    async componentDidMount() {
        this.getUserRoles()
        this.getServicesList()
    }

    getUserRoles = () => {
        this.setState({ userRoleListLoading: true })

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getUserRolesApiCallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.ENDPOINTS.USER_ROLE);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.API_METHOD.GET);
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    getServicesList = () => {
        const { establishmentId } = this.state
        if (!establishmentId) return
        this.setState({ serviceListLoading: true })
        const header = {
            "token": this.state.apiToken
        };
        const endpoint = `${configJSON.ENDPOINTS.GET_SERVICE_SUB_SERVICES}?establishment_id=${establishmentId}`

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getServicesListApiCallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.API_METHOD.GET);
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    handleForm1Submit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (this.validateForm1()) {
            this.setState({ formNumber: 2 });
        }
    }

    handleAddBackClick = () => {
        this.setState({ formNumber: 1 });
    };

    handleClose = () => {
        this.props.handleCloseAddEmployee()
    }
    handleChangePhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;

        if (files && files[0]) {
            const file = files[0];
            const fileSize = file.size / (1024 * 1024); // File size in MB
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];

            // Validate file type
            if (!allowedTypes.includes(file.type)) {
                this.setError('photo', configJSON.DEFAULT_MSG.INVALID_PHOTO_TYPE)
                return;
            }

            // Validate file size
            if (fileSize > 5) {
                this.setError('photo', configJSON.DEFAULT_MSG.INVALID_PHOTO_SIZE)
                return;
            }

            this.setState((prevState) => ({
                employeeForm: {
                    ...prevState.employeeForm,
                    photo: {
                        ...prevState.employeeForm.photo,
                        file: file,
                        url: URL.createObjectURL(file),
                    },
                },
            }));
            this.setError('photo', "")
        }
    };

    handleChangeName = (nameValue: string) => {
        const value = nameValue.trimStart()
        this.setState({
            employeeForm: {
                ...this.state.employeeForm,
                name: value
            },
        })
        this.setError("name", this.getNameError(value))
    }
    handleChangeRole = (roleId: string) => {
        this.setState({
            employeeForm: {
                ...this.state.employeeForm,
                roleId: roleId
            }
        })
        this.setError("roleId", this.getRoleError(roleId))
    }
    handleChangeEmail = (emailValue: string) => {
        const value = emailValue.trimStart()
        this.setState({
            employeeForm: {
                ...this.state.employeeForm,
                email: value
            }
        })
        this.setError("email", this.getEmailError(value))
    }
    handleChangePhone = (phoneValue: string) => {
        const value = phoneValue.trimStart()
        this.setState({
            employeeForm: {
                ...this.state.employeeForm,
                phone: value
            }
        })
        this.setError("phone", this.getPhoneError(value))
    }

    handleChangeService = (serviceId: string) => {
        const subService = this.state.serviceList.find((service) => String(service.service_id) == String(serviceId))
        const subServiceList = subService && subService.sub_services.length ? subService.sub_services : []
        this.setState({
            employeeForm: {
                ...this.state.employeeForm,
                serviceId: serviceId,
                subServiceIds: []
            },
            subServiceList: subServiceList
        })
        this.setError("serviceId", this.getServiceError(serviceId))
    }

    handleChangeSubService = (event: React.ChangeEvent<{ value: unknown }>) => {
        const valueArr = event.target.value as string[]
        this.setState({
            employeeForm: {
                ...this.state.employeeForm,
                subServiceIds: valueArr
            }
        })
    }

    handleChangeAvailability = (checkedValue: boolean, Day: DayKey) => {
        const availabilityClone = [...this.state.employeeForm.availability]
        const updatedAvailability = availabilityClone.map((record) => {
            if (record.day == Day) {
                return {
                    day: Day,
                    checked: checkedValue,
                    start_time: "",
                    end_time: "",
                }
            }
            return record
        })

        this.setState({
            employeeForm: {
                ...this.state.employeeForm,
                availability: updatedAvailability
            },

            formErrors: {
                ...this.state.formErrors,
                availability: {
                    ...this.state.formErrors.availability,
                    [Day]: ""
                }
            }

        })
    };

    handleChangeTimes = (value: string, day: DayKey, type: "start_time" | "end_time") => {
        const availabilityClone = [...this.state.employeeForm.availability]

        const updatedAvailability = availabilityClone.map((record) => {
            if (record.day == day && type == "start_time") {
                return {
                    ...record,
                    start_time: value,
                    end_time: "",
                }
            }
            if (record.day == day && type == "end_time") {
                return {
                    ...record,
                    end_time: value,
                }
            }
            return record
        })

        this.setState({
            employeeForm: {
                ...this.state.employeeForm,
                availability: updatedAvailability
            },
            formErrors: {
                ...this.state.formErrors,
                availability: {
                    ...this.state.formErrors.availability,
                    [day]: ""
                }
            }
        })
    }

    showSubService = () => {
        const { employeeForm, serviceList } = this.state
        const selectedService = serviceList.find((service) => String(service.service_id) == String(employeeForm.serviceId))
        const allowedServices: string[] = configJSON.SUB_SERVICES_FOR_EMPLOYEES

        return selectedService && allowedServices.includes(selectedService.service_name)
    }
    getPhotoError = (photo: File | null) => {
        if (!photo) {
            return configJSON.DEFAULT_MSG.PHOTO_EMPTY
        }
        const fileSize = photo.size / (1024 * 1024); // File size in MB
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];

        if (!allowedTypes.includes(photo.type)) {
            return configJSON.DEFAULT_MSG.INVALID_PHOTO_TYPE
        }

        if (fileSize > 5) {
            return configJSON.DEFAULT_MSG.INVALID_PHOTO_SIZE
        }
        return ""
    }
    getNameError = (nameValue: string) => {
        if (!nameValue) {
            return configJSON.DEFAULT_MSG.NAME_EMPTY
        }
        return ""
    }
    getRoleError = (roleValue: string) => {
        if (!roleValue) {
            return configJSON.DEFAULT_MSG.ROLE_EMPTY
        }
        return ""
    }
    getEmailError = (emailValue: string) => {
        if (!emailValue || !isValidEmail(emailValue)) {
            return configJSON.DEFAULT_MSG.EMAIL_EMPTY
        }
        return ""
    }
    getPhoneError = (phoneValue: string) => {
        if (!phoneValue) {
            return configJSON.DEFAULT_MSG.PHONE_EMPTY
        }
        return ""
    }
    getServiceError = (serviceValue: string) => {
        if (!serviceValue) {
            return configJSON.DEFAULT_MSG.SERVICE_EMPTY
        }
        return ""
    }
    getSubServiceError = (subServiceValue: string[]) => {
        if (!subServiceValue.length) {
            return configJSON.DEFAULT_MSG.SUBSERVICE_EMPTY
        }
        return ""
    }
    validateForm1 = () => {

        const { photo, name, roleId, email, phone, serviceId, subServiceIds } = this.state.employeeForm
        let photo_error = this.getPhotoError(photo.file)
        let name_error = this.getNameError(name.trim())
        let role_error = this.getRoleError(roleId)
        let email_error = this.getEmailError(email)
        let phone_error = this.getPhoneError(phone)
        let service_error = this.getServiceError(serviceId)
        let sub_service_error = ""
        if (this.showSubService()) {
            sub_service_error = this.getSubServiceError(subServiceIds)
        }

        const errorObj = {
            photo: photo_error,
            name: name_error,
            roleId: role_error,
            email: email_error,
            phone: phone_error,
            serviceId: service_error,
            subServiceIds: sub_service_error,
        }
        this.setState({
            formErrors: {
                ...this.state.formErrors,
                ...errorObj
            }
        })

        const hasErrors = Object.values(errorObj).some(value => Boolean(value));

        return !hasErrors
    }
    validateForm2 = () => {
        const { employeeForm } = this.state
        const availabilityClone = [...employeeForm.availability]

        let errorObj: Record<DayKey, string> = {
            Monday: "",
            Tuesday: "",
            Wednesday: "",
            Thursday: "",
            Friday: "",
            Saturday: "",
            Sunday: ""
        }
        availabilityClone.forEach((eachDay) => {

            if (eachDay.checked) {
                if (!eachDay.start_time) {
                    errorObj[eachDay.day] = configJSON.DEFAULT_MSG.START_TIME_EMPTY
                    return
                }
                if (!eachDay.end_time) {
                    errorObj[eachDay.day] = configJSON.DEFAULT_MSG.END_TIME_EMPTY
                    return
                }
            } else {
                errorObj[eachDay.day] = ""
            }
        })
        this.setState({
            formErrors: {
                ...this.state.formErrors,
                availability: errorObj
            }
        })

        const hasErrors = Object.values(errorObj).some(value => Boolean(value));

        return !hasErrors
    }
    getBody = () => {
        const { establishmentId } = this.state

        const { photo, name, roleId, email, phone, serviceId, subServiceIds, availability } = this.state.employeeForm
        const reqForm = new FormData()

        reqForm.append("establishment_id", establishmentId)

        if (photo.file) {
            reqForm.append("employee[image]", photo.file)
        }

        reqForm.append("employee[name]", name)
        reqForm.append("employee[role_id]", roleId)
        reqForm.append("employee[email]", email)
        reqForm.append("employee[phone]", phone)
        reqForm.append("employee[service_id]", serviceId)

        subServiceIds.forEach((subServiceId) => {
            reqForm.append("employee[sub_service_ids][]", subServiceId)
        })
        const selectedDays = availability.filter((record) => record.checked)

        if (selectedDays.length) {
            selectedDays.forEach((eachDay) => {
                reqForm.append(`schedule_information[days][${eachDay.day}][start_time]`, eachDay.start_time)
                reqForm.append(`schedule_information[days][${eachDay.day}][end_time]`, eachDay.end_time)
            })
        }

        return reqForm
    }

    isFormHasError = () => {

        const { photo, name, roleId, email, phone, serviceId, subServiceIds } = this.state.employeeForm

        let photo_error = this.getPhotoError(photo.file)
        let name_error = this.getNameError(name.trim())
        let role_error = this.getRoleError(roleId)
        let email_error = this.getEmailError(email)
        let phone_error = this.getPhoneError(phone)
        let service_error = this.getServiceError(serviceId)
        let sub_service_errormsg = ""
        if (this.showSubService()) {
            sub_service_errormsg = this.getSubServiceError(subServiceIds)
        }

        const errorObj = {
            photo: photo_error,
            name: name_error,
            roleId: role_error,
            email: email_error,
            phone: phone_error,
            serviceId: service_error,
            subServiceIds: sub_service_errormsg,
        }

        const isError = Object.values(errorObj).some(value => Boolean(value));

        return isError
    }

    addEmployee = () => {
        if (this.validateForm1() && this.validateForm2()) {
            this.setState({ addEmployeeLoading: true })
            const header = {
                "token": this.state.apiToken
            };
            const bodyData = this.getBody()
            const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.addEmployeeApiCallId = reqMessage.messageId;
            reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.ENDPOINTS.ADD_EMPLOYEE);
            reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.API_METHOD.POST);
            reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), bodyData);
            runEngine.sendMessage(reqMessage.id, reqMessage);
        }
    }

    setError = (key: keyof IEmployeeFormError, errorMsg: string) => {
        this.setState({
            formErrors: {
                ...this.state.formErrors,
                [key]: errorMsg
            }
        })
    }
    setMsgModal = (open: boolean, msg: string) => {
        this.setState({
            modalOpen: open,
            modalMsg: msg
        })
    }
    closeMsgModal = () => {
        this.setState({
            modalOpen: false,
            modalMsg: ""
        })
    }
    // Customizable Area End
}