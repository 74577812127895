export const days: { [key: string]: string } = {
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
    Sun: 'Sunday',
  };


  export const convertAbbreviationToFullDay = (abbreviation: string): string => {
    return days[abbreviation] || abbreviation; 
  };

  const reverseDays = Object.fromEntries(
    Object.entries(days).map(([abbreviation, fullDay]) => [fullDay, abbreviation])
  );
  
  export const convertFullDayToAbbreviation = (fullDay: string): string => {
    return reverseDays[fullDay] || fullDay;
  };