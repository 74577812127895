import { Box, Typography, styled } from '@material-ui/core';
import React from 'react';


const ContentWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    width: '100%',
    borderBottom: '1px solid #D9D9D9',
    "& .field-label": {
        color: '#6F05EA',
        fontSize: '12px',
        fontWeight: 400
    },
    "& .field-value": {
        color: '#000000',
        fontSize: '18px',
        fontWeight: 500
    },
    "& .field-padding": {
        paddingLeft: '8px'
    }
});

type Props = {
    title: string,
    value: string | undefined, 
    withPadding?: boolean, 
    withOpacity?: boolean,
    isYellow?: boolean
}

const Field = ({title, value, withPadding, withOpacity, isYellow }: Props) => {
    return (
        <ContentWrapper {...(withOpacity && { style: { opacity: 0.6 }})}>
            <Typography className='field-label' {...(isYellow && { style: { color: '#D4AD2D'}})}>{title}</Typography>
            <Typography className={`'field-value' ${withPadding ? 'field-padding' : ''}`} style={{ fontSize: '18px', fontWeight: 500 }}>{value ? value : '---'}</Typography>
        </ContentWrapper>
    )
}

export default Field;