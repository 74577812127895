export const enScheduling = {
    //TITLE
    "About": "About",
    "Commodities": "Commodities",
    "Complementary services": "Complementary services",
    "Cancellation policy": "Cancellation policy",
    "Location": "Location",
    //TABLE
    "Room Type": "Room Type",
    "Capacity": "Capacity",
    "Price": "Price",
    "Service Type": "Service Type",
    "Space Type": "Space Type",
    "Recurrence": "Recurrence",
    "Once a week": "Once a week",
    "Twice a week": "Twice a week",
    "3 times a week": "3 times a week",
    "4 times a week": "4 times a week",
    "5 times a week": "5 times a week",
    "6 times a week": "6 times a week",
    "7 times a week": "7 times a week",
    //BOOKING
    "Select Pet": "Select Pet",
    "Start Date": "Start Date",
    "End Date": "End Date",
    "Select Time": "Select Time",
    "You will not be charged yet": "You will not be charged yet",
    "Total": "Total",
    "Repeat Weekly": "Repeat Weekly",
    "Hotels": "Hotels",
    "Dog Walking": "Dog Walking",
    "Pet Sitting": "Pet Sitting",
    "Day Care": "Day Care",
    "Training": "Training",
    "Morning":"Morning",
    "Evening":"Evening",
    "Repeat Every":"Repeat Every",
    "01 weeks":"01 weeks",
    "02 weeks":"02 weeks",
    "03 weeks":"03 weeks",
    "04 weeks":"04 weeks",
    //DAYS
    "Sun": "Sun",
    "Mon": "Mon",
    "Tue": "Tue",
    "Wed": "Wed",
    "Thu": "Thu",
    "Fri": "Fri",
    "Sat": "Sat",
    //PLACEHOLDER
    "Select End Date": "Select End Date",
    //ERROR
    "Please select Pet": "Please select Pet",
    "Please select End Date": "Please select End Date",
    "Please select Time": "Please select Time",
    "Please select based on recurrence": "Please select based on recurrence",
    "Transportation will be confirmed by the vendor.":"Transportation will be confirmed by the vendor.",
    //MODEL
    "Please select a room or service to book.": "Please select a room or service to book.",
    "Please select the same number of days as you have selected": "Please select the same number of days as you have selected",
    "once a week": "once a week",
    "twice a week": "twice a week",
    "Let's find a better time for you!":"Let's find a better time for you!",
    "Capacity is full between these dates":"Capacity is full between these dates",
    "Looks like":"Looks like",
    "is booked up.":"is booked up.",
    //GALLERY 
    "Description": "Description",
    //BUTTON
    "Selected Room": "Selected Room",
    "Select": "Select",
    "Selected": "Selected",
    "Book Now": "Book Now",
    "Select Room": "Select Room",
    "View More": "View More",
    "Reserve Now": "Reserve Now",
    "Close": "Close",
    "Okay": "Okay",
    "View Map":"View Map",
    "Back":"Back",
    "Select different Time":"Select different Time",
    //NO DATA
    "No Data Found": "No Data Found",
    "No Sub Service Available":"No Sub Service Available",
    "No Commodities Available":"No Commodities Available",
    //CHECKOUT PAGE
        "Booking Details":"Booking Details",
        "Reservation date":"Reservation date",
        "nights":"nights",
        "Select Card":"Select Card",
        "select your payment card":"select your payment card",
        "Add new card":"Add new card",
        "Card details":"Card details",
        "Update your payment cards":"Update your payment cards",
        "Set as Default":"Set as Default",
        "Payment Success":"Payment Success",
        "Your payment has been successfully processed and your booking is confirmed.":"Your payment has been successfully processed and your booking is confirmed.",
        "Payment Failed":"Payment Failed",
        "Unfortunately, your payment could not be processed. Please check your payment details and try again.":"Unfortunately, your payment could not be processed. Please check your payment details and try again.",
        //FIELD
        "Name":"Name",
        "Card Number":"Card Number",
        "Valid Till":"Valid Till",
        "Security Code":"Security Code",
        //PLACEHOLDER
        "The name on the card":"The name on the card",
        "MM / YY":"MM / YY",
        "CVC":"CVC",
        //ERROR
        "Please enter Name":"Please enter Name",
        "Please enter valid Card Number":"Please enter valid Card Number",
        "Please enter valid Expiry":"Please enter valid Expiry",
        "Please enter valid Code":"Please enter valid Code",
        "Your card number is invalid.":"Your card number is invalid.",
        "Your card's expiration year is invalid.":"Your card's expiration year is invalid.",
        "Only Visa and Mastercard is allowed":"Only Visa and Mastercard is allowed",
        //BUTTON
        "Cancel":"Cancel",
        "Save":"Save",
        "Pay Now":"Pay Now",
        "Yes, I'm Sure":"Yes, I'm Sure",
        "No, Continue Payment":"No, Continue Payment",
        "Home Page":"Home Page",
        //MODEL
        "Are you sure you want to cancel without completing the payment?":"Are you sure you want to cancel without completing the payment?",
    //CARD STRIPE
        "Stripe not loaded yet":"Stripe not loaded yet"
}

export const ptScheduling = {
    //TITLE
    "About": "Sobre",
    "Commodities": "Mercadorias",
    "Complementary services": "Serviços complementares",
    "Cancellation policy": "Política de cancelamento",
    "Location": "Localização",
    //TABLE
    "Room Type": "Tipo de quarto",
    "Capacity": "Capacidade",
    "Price": "Preço",
    "Service Type": "Tipo de serviço",
    "Space Type": "Tipo de espaço",
    "Recurrence": "Recorrência",
    "Once a week": "Uma vez por semana",
    "Twice a week": "Duas vezes por semana",
    "3 times a week": "3 vezes por semana",
    "4 times a week": "4 vezes por semana",
    "5 times a week": "5 vezes por semana",
    "6 times a week": "6 vezes por semana",
    "7 times a week": "7 vezes por semana",
    //BOOKING
    "Select Pet": "Selecione animal de estimação",
    "Start Date": "Data de início",
    "End Date": "Data de término",
    "Select Time": "Selecione a hora",
    "You will not be charged yet": "Você ainda não será cobrado",
    "Total": "Total",
    "Repeat Weekly": "Repita semanalmente",
    "Hotels": "Hotéis",
    "Dog Walking": "Passear com cachorro",
    "Pet Sitting": "Pet Sitting",
    "Day Care": "Creche",
    "Training": "Treinamento",
    "Morning":"Manhã",
    "Evening":"Noite",
    "Repeat Every":"Repita a cada",
    "01 weeks":"01 semana",
    "02 weeks":"02 semana",
    "03 weeks":"03 semana",
    "04 weeks":"04 semana",
    //DAYS
    "Sun": "Dom",
    "Mon": "Seg",
    "Tue": "Ter",
    "Wed": "Que",
    "Thu": "Qui",
    "Fri": "Sex",
    "Sat": "Sáb",
    //PLACEHOLDER
    "Select End Date": "Selecione a data de término",
    //ERROR
    "Please select Pet": "Por favor selecione Animal de Estimação",
    "Please select End Date": "Selecione a data de término",
    "Please select Time": "Por favor selecione Hora",
    "Please select based on recurrence": "Selecione com base na recorrência",
    "Transportation will be confirmed by the vendor.":"O transporte será confirmado pelo fornecedor.",
    //MODEL
    "Please select a room or service to book.": "Por favor selecione um quarto ou serviço para reservar.",
    "Please select the same number of days as you have selected": "Selecione o mesmo número de dias que você selecionou",
    "once a week": "uma vez por semana",
    "twice a week": "duas vezes por semana",
    "Let's find a better time for you!":"Vamos encontrar um horário melhor para você!",
    "Capacity is full between these dates":"A capacidade está cheia entre essas datas",
    "Looks like":"Parece",
    "is booked up.":"está reservado.",
    //GALLERY 
    "Description": "Descrição",
    //BUTTON
    "Selected Room": "Quarto selecionado",
    "Select": "Selecione",
    "Selected": "Selecionado",
    "Book Now": "Reserve agora",
    "Select Room": "Selecione o quarto",
    "View More": "Ver mais",
    "Reserve Now": "Reserve agora",
    "Close": "Fechar",
    "Okay": "Ok",
    "View Map":"Ver mapa",
    "Back":"Voltar",
    //NO DATA
    "No Data Found": "Nenhum dado encontrado",
    "No Sub Service Available":"Nenhum subserviço disponível",
    "No Commodities Available":"Nenhuma mercadoria disponível",
    "Select different Time":"Selecione um horário diferente",
    //CHECKOUT PAGE
        "Booking Details":"Detalhes da reserva",
        "Reservation date": "Data da reserva ",
        "nights": "noites ",
        "Select Card": "Selecione o cartão ",
        "select your payment card": "selecione seu cartão de pagamento ",
        "Add new card": "Adicionar novo cartão ",
        "Card details": "Detalhes do cartão ",
        "Update your payment cards": "Atualize seus cartões de pagamento ",
        "Set as Default": "Definir como padrão ",
        "Payment Success": "Sucesso no pagamento ",
        "Your payment has been successfully processed and your booking is confirmed.": "Seu pagamento foi processado com sucesso e sua reserva foi confirmada. ",
        "Payment Failed": "Falha no pagamento ",
        "Unfortunately, your payment could not be processed. Please check your payment details and try again.": "Infelizmente, não foi possível processar seu pagamento. Verifique seus detalhes de pagamento e tente novamente. ",
        //FIELD
        "Name": "Nome ",
        "Card Number": "Número do cartão ",
        "Valid Till": "Válido até ",
        "Security Code": "Código de segurança ",
        //PLACEHOLDER
        "The name on the card": "O nome no cartão ",
        "MM / YY": "MM / AA ",
        "CVC": "CVC ",
        //ERROR
        "Please enter Name": "Por favor insira o nome ",
        "Please enter valid Card Number": "Por favor insira um número de cartão válido ",
        "Please enter valid Expiry": "Por favor insira um vencimento válido ",
        "Please enter valid Code": "Por favor insira um código válido ",
        "Your card number is invalid.": "O número do seu cartão é inválido. ",
        "Your card's expiration year is invalid.": "O ano de validade do seu cartão é inválido. ",
        "Only Visa and Mastercard is allowed": "Somente Visa e Mastercard são permitidos ",
        //BUTTON
        "Cancel": "Cancelar ",
        "Save": "Salvar ",
        "Pay Now": "Pague agora ",
        "Yes, I'm Sure": "Sim, tenho certeza ",
        "No, Continue Payment": "Não, continuar o pagamento ",
        //MODEL
        "Are you sure you want to cancel without completing the payment?": "Tem certeza de que deseja cancelar sem concluir o pagamento? ",
    //CARD STRIPE
        "Stripe not loaded yet": "Stripe ainda não carregado",
}