import { Box } from '@material-ui/core';
import React from 'react'
import { GenericFormikProps } from '../../types';
import StartDateChange from './StartDateChange';
import TimeChange from './TimeChange';
import EndDateChange from './EndDateChange';
import RecurrenceChange from './RecurrenceChange';
import DayOfWeekChange from './DayOfWeekChange';


const DogWalkingContent = (props: GenericFormikProps) => {
    const { event, isAll, isReccurence } = props;
    const isDogWalking = event.service.service_type === "Dog Walking";
    const isSelectedAll = isAll && isReccurence;

    if(!isDogWalking) {
        return null;
    }

   return (
    <Box className="form-wrapper">
        <StartDateChange {...props} isReccurence={isSelectedAll} />
        {isSelectedAll && <EndDateChange {...props} />}
        <TimeChange {...props} />
        {isSelectedAll && <RecurrenceChange {...props} />}
        {isSelectedAll && <DayOfWeekChange {...props} />}
    </Box>
   ) 
}

export default DogWalkingContent;