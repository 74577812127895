Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "scheduling";
exports.labelBodyText = "scheduling Body";

exports.btnExampleTitle = "CLICK ME";
exports.getApiMethodType = "GET";
exports.applicationJsonApiContentType = "application/json";
exports.serviceProviderAPiEndPoint = "scheduling/service_provider_schedulings/get_sp_details";
exports.STRIPE = {
  PUBLISHABLE_KEY: "pk_test_51Q3zY3C4cTAbEgpIZo9cJJzZg1Fute56MAHLuhYQiCnnL9mdpJIUhSvtCO1higyipZf8PbJtAeQxhQv0fN74KDYI00oE75vWyT",
}
exports.API_METHOD = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE"
}

exports.ENDPOINTS = {
  GET_PET_LIST: "account_block/animal_types",
  GET_SERVICE_DETAILS: "account_block/services",
  CREATE_BOOKING: "bx_block_scheduling/schedules",
  GET_SUB_SERVICE_GALLERY: "account_block/sub_services/show_gallery?id=",
  MAKE_PAYMENT: "bx_block_stripe_integration/payment_intents",
  GET_BOOKING_DETAILS: "bx_block_scheduling/schedules",
  // NEW
  CREATE_PAYMENT_CARD: "bx_block_stripe_integration/payment_methods",
  GET_PAYMENT_CARD_LIST: "bx_block_stripe_integration/payment_methods",
  DELETE_CARD: "bx_block_stripe_integration/payment_methods/delete_card",
  SET_DEFAULT_CARD: "bx_block_stripe_integration/payment_methods/set_default_card",
  CANCEL_PAYMENT : "bx_block_scheduling/schedules/delete_schedules_after_timeout?schedule_id="
}

exports.SERVICES_ENUM = {
  DAY_CARE: "Day Care",
  DOG_WALKING: "Dog Walking",
  HOTELS: "Hotels",
  GROOMING: "Grooming",
  PET_SITTING: "Pet Sitting",
  TRAINING: "Training"
}

exports.RECURRENCE_KEY = ["Once a week", "Twice a week", "3 times a week", "4 times a week", "5 times a week", "6 times a week", "7 times a week"];
exports.COMPLEMENTARY_SERVICE = ["medication", "transportation"];
exports.MEDICATION_SERVICE = "medication"
exports.TRANSPORTATION_SERVICE = "transportation"

exports.FIELDS_ERROR = {
  PET: "Please select Pet",
  NO_PET_FOUND: "No pets found! Please add your own pet to book the service.",
  START_DATE: "Please select Start Date",
  END_DATE: "Please select End Date",
  SHIFT_TIME: "Please select Time",
  REPEAT_DAYS: "Please select based on recurrence",
  TIME_VALUE: "Please select Time",
}
exports.DEFAULT_MSG = {
  UPDATE_PAYMENT_CARD: "Update your payment cards",
  NOT_CHARGED: "You will not be charged yet",
  SELECT_SUB_SERVICE_MSG: "Please select a room or service to book.",
  TRANSPORTATION_WARNING: "Transportation will be confirmed by the vendor.",
  SUCCESS_BOOKING: "Service booked Successfully.",
  CAPACITY_SUBTITLE: "Let's find a better time for you!",
  CAPACITY_FULL_ERROR: "Capacity is full between these dates",
  PAYMENT_SUCCESS: "Your payment has been successfully processed and your booking is confirmed.",
  PAYMENT_FAILED: "Unfortunately, your payment could not be processed. Please check your payment details and try again.",
  SUCCESS_CARD_DELETE: "Card delete successfully",
  SUCCESS_SET_CARD: "Default card updated successfully",
  CARD_ALREADY_EXIST: "Card details already exist",
  CANCEL_CONFIRMATION: "Are you sure you want to cancel without completing the payment?",
  CANCEL_CONFIRMATION_BTN: "Yes, I'm Sure",
  CANCEL_CLOSE_BTN: "No, Continue Payment",
  BOOKING_NOT_FOUND_TITLE: "Oops! Booking Not Found",
  BOOKING_NOT_FOUND_SUBTITLE: "We couldn't locate any booking records. Please create a new booking to proceed.",
  TIMEOUT_TITLE: "Session Expired",
  TIMEOUT_MSG: "Your payment session has expired. Please select again the booking you want to proceed with again"
}
exports.CARD_ERROR = {
  STRIPE_NOT_LOADED: "Stripe not loaded yet",
  NAME: "Please enter Name",
  NUMBER: "Please enter valid Card Number",
  EXPIRY: "Please enter valid Expiry",
  CVV: "Please enter valid Code",
  CARD_BRAND: "Only Visa and Mastercard is allowed"
}

exports.timeMenuList = [
  {
    name: "01:00",
    value: "01:00",
  },
  {
    name: "02:00",
    value: "02:00",
  },
  {
    name: "03:00",
    value: "03:00"
  },
  {
    name: "04:00",
    value: "04:00"
  },
  {
    name: "05:00",
    value: "05:00"
  },
  {
    name: "06:00",
    value: "06:00"
  },
  {
    name: "07:00",
    value: "07:00"
  },
  {
    name: "08:00",
    value: "08:00"
  },
  {
    name: "09:00",
    value: "09:00"
  },
  {
    name: "10:00",
    value: "10:00"
  },
  {
    name: "11:00",
    value: "11:00"
  },
  {
    name: "12:00",
    value: "12:00",
  },
]
exports.repeatEveryMenuList = [
  {
    name: "01 weeks",
    value: "01 weeks",
  },
  {
    name: "02 weeks",
    value: "02 weeks",
  },
  {
    name: "03 weeks",
    value: "03 weeks"
  },
  {
    name: "04 weeks",
    value: "04 weeks"
  }
]
exports.getMiliSeconds = (minutes) => {
  return Number(minutes) * 60 * 1000
}

// Customizable Area End