import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Client } from '@twilio/conversations';
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
}
interface IChatResponse {
  id: string;
  attributes: {
    name: string;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: {
      id: string;
      attributes: { id: number; message: string };
      message: string;
    };
  };
}
//Love Pet Chat Interface
interface IApiModel {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string | null;
  isJsonStringify?: boolean;
}
interface ChatRoom {
  id: number;
  name: string;
  chat_type: string; // e.g., "single_user"
  muted: boolean;
  last_message: string | null;
  unread_messages: number;
  last_message_time: string;
  created_at: string; // ISO 8601 date format
  updated_at: string; // ISO 8601 date format
}

export interface UserData {
  phone_number: number;
  name: string;
  role: string; // e.g., "customer", "vendor"
  email: string;
  image_url: string;
}

interface TwilioConversation {
  sid: string;
  account_sid: string;
  friendly_name: string;
  unique_name: string;
  state: string; // e.g., "active"
  chat_service_sid: string;
  messaging_service_sid: string;
  attributes: string; // JSON string
  date_created: string; // ISO 8601 date format
  date_updated: string; // ISO 8601 date format
}

interface ChatData {
  chat_room: ChatRoom;
  current_user_data: UserData;
  other_user_data: UserData;
  twilio_conversation: TwilioConversation;
  last_message: string;
  last_message_time: string
  error: string | null; // could be null or an error message
}

interface ChatResponse {
  data: ChatData[];
  chat_room_count: number;
  message: string;
  unread_messages: number;
  error: string | null; // Include error property
  errors?: any; // Optional if it can be undefined
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  accountId: number;
  chatName: string;
  chatList: IChat[];
  isVisibleModal: boolean;
  chatUserId: number | string;
  isChatListShow: boolean;
  //twilio Chat
  UserChatList: ChatData[];
  isLoading: boolean;
  searchText: any;
  filteredChatList: ChatData[];
  searchQuery: any,
  currentUserName: string,
  currentUserProfile: string
  conversationSID: string | null;
  currentMenuItem: string | null;
  accessTokenid: any
  twilioClient: any
  currentConversation: any
  errorMessage: string;
  totalUnreadMessages: number
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatListApiCallId: string = "";
  createChatRoomApiCallId: string = "";
  //LovePet Chat
  getUserChatListAPIId: string = "";
  accessTokenApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      chatName: "",
      chatList: [],
      isVisibleModal: false,
      chatUserId: 1,
      isChatListShow: true,
      //twilio Chat
      UserChatList: [],
      isLoading: true,
      searchText: '',
      filteredChatList: [],
      searchQuery: '',
      currentUserName: localStorage.getItem("login_user_name") || '',
      currentUserProfile: localStorage.getItem("login_user_profile") || '',
      conversationSID: '',
      currentMenuItem: localStorage.getItem("selectedValueVendor") !== null ? localStorage.getItem("selectedValueVendor") : "0",

      accessTokenid: '',
      twilioClient: '',
      currentConversation: '',
      errorMessage: '',
      totalUnreadMessages: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getAccessToken()
    this.GetUserChatListData();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
      });
    }
    this.initializeTwilioClient();
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  isStringNullOrBlank = (string: string) => {
    return string === undefined || string === null || string.length === 0;
  };

  showModal = () => {
    this.setState({ isVisibleModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  navigateToChatView = (chatId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ViewChat");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      chatId: chatId,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  getChatList = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyChatsApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createChatRoom = (chatName: string) => {
    if (this.isStringNullOrBlank(chatName)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
        "Access-Control-Allow-Origin": "*",
      };
      const bodyData = {
        name: chatName,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createChatRoomApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createChatRoomApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  inputRoomNameProps = {
    onChangeText: (chatName: string) => {
      this.setState({ chatName });
    },
  };

  btnAddRoomProps = {
    onPress: () => this.createChatRoom(this.state.chatName),
  };

  btnCloseModalProps = {
    onPress: () => this.hideModal(),
  };

  btnShowAddModalProps = {
    onPress: () => {
      this.showModal();
    },
  };

  handleChatNameChange = (chatName: string) => {
    this.setState({ chatName });
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
    if (
      responseJson?.data &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getChatListApiCallId.length > 0 &&
      apiRequestCallId === this.getChatListApiCallId
    ) {
      this.getChatListApiCallId = "";
      const chatList = responseJson.data;
      const results = chatList.map((item: IChatResponse) => {
        const findAccountMuteResult = item.attributes.accounts_chats.find(
          (item) => item.attributes.account_id === this.state.accountId
        )?.attributes.muted;
        return {
          id: item.id,
          name: item.attributes.name,
          muted:
            findAccountMuteResult ??
            item.attributes.accounts_chats[0].attributes.muted,
          unreadCount:
            item.attributes.accounts_chats[0].attributes.unread_count,
          lastMessage: item.attributes.messages?.attributes?.message,
        };
      });
      this.setState({
        chatList: results,
      });
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createChatRoomApiCallId.length > 0 &&
      apiRequestCallId === this.createChatRoomApiCallId &&
      responseJson
    ) {
      this.createChatRoomApiCallId = "";
      this.hideModal();
      this.getChatList(this.state.token);
    }
    //LovePet Chat
    if (apiRequestCallId === this.getUserChatListAPIId) {
      this.handleUserChatListResponse(responseJson);
    }
    if (apiRequestCallId === this.accessTokenApiId) {
      this.handleAccessToken(responseJson);
    }
  }
  handleChatListUserClick = (userId: any) => {
    const conversationSID = userId?.twilio_conversation?.sid || '';
    this.setState(
      {
        chatUserId: userId?.chat_room?.id || '',
        isChatListShow: false,
        conversationSID: conversationSID,
      },
      () => {
        if (conversationSID) {
          this.markMessagesAsRead(conversationSID);
        } else {
          console.warn("Twilio conversation SID is null or empty.");
        }
      }
    );
  }
  handleClickBackChatList = () => {
    this.setState({ isChatListShow: true })
  }
  //LovePet Chat
  getAccessToken = async () => {
    const userInfoToken = localStorage.getItem("login_token");
    this.accessTokenApiId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.postApiMethod,
      endPoint: configJSON.accessTokenendPoint,
      token: userInfoToken
    });
  };
  GetUserChatListData = async () => {
    const userInfoToken = localStorage.getItem("login_token");
    this.getUserChatListAPIId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getUserChatListEndpoint,
      token: userInfoToken,
    });
  };
  apiCall = async (data: IApiModel) => {
    const { method, endPoint, body, token, isJsonStringify } =
      data;
    let header;
    header = {
      token: token,
    };
    const requestMessaged = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessaged.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessaged.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessaged.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessaged.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        isJsonStringify ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessaged.id, requestMessaged);
    return requestMessaged.messageId;
  };
  handleUserChatListResponse = async (responseJson: ChatResponse) => {
    this.setState({ isLoading: false })
    if (responseJson?.data) {
      if (responseJson?.data?.length > 0) {
        const firstChat = responseJson.data[0];
        const chatRoomId = firstChat?.chat_room?.id || '';
        const conversationSID = firstChat?.twilio_conversation?.sid || null;
      
          this.setState(
            {
              UserChatList: responseJson.data,
              isLoading: false,
              chatUserId: chatRoomId,
              isChatListShow: false,
              filteredChatList: responseJson.data,
              conversationSID
            },
            () => {
              this.initializeTwilioClient();
            }
          );
      } 
      else {
        this.setState({ isLoading: false })
      }
    }

  }
  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = event.target.value.toLowerCase();
    this.setState({ searchQuery }, () => {
      const filteredChatList = this.state.UserChatList.filter(chatData =>
        chatData.last_message.toLowerCase().includes(searchQuery) ||
        chatData.other_user_data.name.toLowerCase().includes(searchQuery)
      );
      this.setState({ filteredChatList });
    });
  };
  openListOfEstablishment = () => {
    this.setState({
      currentMenuItem: "4"
    })
  }
  handleCustomerHeaderSelect = (selectedValue: string) => {
    this.setState(prevState => ({
      currentMenuItem: selectedValue === "4" ? prevState.currentMenuItem : selectedValue
    }));
  }
  handleAccessToken(response: any) {
    if (response) {
      if (response.access_token) {
        this.setState({ accessTokenid: response.access_token, errorMessage: '' }); // Clear error
      } else if (response.errors) {
        this.setState({ errorMessage: response.errors }); // Store the error message in state
      }
    }
  }

  initializeTwilioClient = async () => {
    const { accessTokenid } = this.state;

    try {
      const client = new Client(accessTokenid);
      // Listen for incoming messages across all conversations
      client.on('messageAdded', this.handleIncomingMessage);
    } catch (error) {
    }
  };
  handleIncomingMessage = async (message: any) => {
    const { filteredChatList } = this.state;
    const isSentByCurrentUser = message.state.author === localStorage.getItem('login_email');
    // Find the conversation in the chat list
    const conversationSID = message.conversation.sid;
    //Check if the message is in currently opened conersation
    const isCurrentChat = conversationSID===this.state.conversationSID
    const chatIndex = filteredChatList.findIndex(
      (chat) => chat.twilio_conversation.sid === conversationSID
    );

    if (chatIndex !== -1) {
      const updatedChatList = [...filteredChatList];
      updatedChatList[chatIndex] = {
        ...updatedChatList[chatIndex],
        chat_room: {
          ...updatedChatList[chatIndex].chat_room,
          last_message: message.body,
          last_message_time: message.dateCreated
            ? message.dateCreated.toISOString()
            : new Date().toISOString(), // Use a fallback timestamp
          unread_messages: !isSentByCurrentUser && !isCurrentChat ?
            (updatedChatList[chatIndex].chat_room.unread_messages || 0) + 1 : updatedChatList[chatIndex].chat_room.unread_messages
        },
      };
      // Update state with the modified chat list
      this.setState({filteredChatList: updatedChatList });
    }

  };
  async getConversation(conversationSID: string) {
    const { accessTokenid } = this.state;

    try {
      const tclient = new Client(accessTokenid);
      const conversation = await tclient.getConversationBySid(conversationSID);

      // Attach a listener to this specific conversation
      conversation.on('messageAdded', (message) => {
        this.handleIncomingMessage(message);
      });

      this.setState({ currentConversation: conversation });
    } catch (error) {
    }
  }
  async componentWillUnmount() {
    const { currentConversation } = this.state;
    if (currentConversation) {
      await currentConversation.removeAllListeners('messageAdded');
    }
  }
  markMessagesAsRead = async (conversationSID: string) => {
    const { filteredChatList } = this.state;
    const chatIndex = filteredChatList.findIndex(
      (chat) => chat.twilio_conversation.sid === conversationSID
    );

    if (chatIndex !== -1) {
      const updatedChatList = [...filteredChatList];

      updatedChatList[chatIndex] = {
        ...updatedChatList[chatIndex],
        chat_room: {
          ...updatedChatList[chatIndex].chat_room,
          unread_messages: 0, // Reset unread count
        },
      };

      // Update the state with the modified chat list
      this.setState({ filteredChatList: updatedChatList });

      // Optionally, call the backend to update the unread count for this chat

    }
  };

  // Customizable Area End
}
