import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Price } from "../../../../components/src/interfaces.web";

// Customizable Area Start
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import i18n from "../../../../components/src/i18next/i18n";

interface ITableData {
  id: number,
  title: string,
  capacity: number,
  allows_pet: string,
  description: string,
  service_id: number,
  duration: string | null,
  shift: string | null,
  created_at: string,
  updated_at: string,
  prices: Price[]
}

interface IGalleySubService {
  capacity: string,
  price: string,
  description: string
}

interface IApiModel {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string | null;
  isJsonStringify?: boolean;
}

interface IGalleries {
  id: number,
  url: string
}

interface ISubServiceResponse {
  galleries: IGalleries[],
  sub_service_data: {
    capacity: number,
    description: string,
    sub_service_price: number[]
  },
  meta: {
    message: string
  }
}
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  serviceType: string;
  data: ITableData[];
  selectedSubService: null | Price,
  setSubService: (subServicePrice: Price | null) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedIds: number,
  recurrenceStates:  {[key: number]: string}; 
  isSubServiceGalleryOpen:boolean;
  subServiceGalleryData:IGalleySubService,
  subServiceImageList:IGalleries[],
  isGalleryLoading:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ServiceBookingTableWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSubServiceGalleryApiId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      selectedIds: 0,
      recurrenceStates: {}, 
      isSubServiceGalleryOpen:false,
      subServiceGalleryData: {} as IGalleySubService,
      subServiceImageList: [],
      isGalleryLoading:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getSubServiceGalleryApiId) {
      this.handleSubServiceResponse(responseJson);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    if (this.props.selectedSubService && this.props.selectedSubService.sub_service_id) {
      const subServiceId = this.props.selectedSubService.sub_service_id;
      this.setState({
        selectedIds: subServiceId,
        recurrenceStates: {
          [subServiceId]: this.props.selectedSubService.id.toString(),
        }
      }, () => {
        this.getButtonName(subServiceId);
        this.getButtonClass(subServiceId);
      });

    }
    const isRecurrenceShow = [configJSON.SERVICES_ENUM.DAY_CARE, configJSON.SERVICES_ENUM.DOG_WALKING].includes(this.props.serviceType);
    const hasEmptyPrices = this.props.data.some(subService =>
      Array.isArray(subService.prices) && subService.prices.length === 0
    );
    if (isRecurrenceShow && !hasEmptyPrices && this.props.data.length) {
      const { data } = this.props;
      const recurrenceData: { [x: number]: string } = {};

      data.forEach((item) => {
        const matchingPrice = item.prices.find((price) => price.title === "Once a week");
        if (matchingPrice) {
          recurrenceData[item["id"]] = matchingPrice.id.toString();
        }
      });
      if (this.props.selectedSubService && this.props.selectedSubService.sub_service_id) {
        const subServiceId = this.props.selectedSubService.sub_service_id
        if (subServiceId in recurrenceData) {
          delete recurrenceData[subServiceId];
        }
        this.setState({
          selectedIds: subServiceId,
          recurrenceStates: {
            ...recurrenceData,
            [subServiceId]: this.props.selectedSubService.id.toString(),
          }
        }, () => {
          this.getButtonName(subServiceId);
          this.getButtonClass(subServiceId);
        });
      } else {
        this.setState({ recurrenceStates: recurrenceData })
      }
    }
  }
  
  getServiceTypeHeaderName = () => {
    switch (this.props.serviceType) {
      case configJSON.SERVICES_ENUM.HOTELS:
        return this.transTable("Room Type")
      case configJSON.SERVICES_ENUM.DAY_CARE:
        return this.transTable("Space Type")
      default:
        return this.transTable("Service Type")
    }
  };

  getButtonName = (id:number) => {
    const { selectedIds } = this.state;
    const isSelectedService = selectedIds === id;
    switch (this.props.serviceType) {
      case configJSON.SERVICES_ENUM.HOTELS:
        return isSelectedService ? this.transTable("Selected Room") : this.transTable("Select Room");
      case configJSON.SERVICES_ENUM.GROOMING:
        return isSelectedService ? this.transTable("Selected") : this.transTable("Select");
      default:
        return isSelectedService ? this.transTable("Selected") : this.transTable("Select");
    }
  };
  

  handleSelectClick = (id: number, SelectedPriceRecord: Price | undefined) => {
    this.setState({
      selectedIds: id,
    });
    SelectedPriceRecord && this.props.setSubService(SelectedPriceRecord)
  };

  handleRecurrenceChange = (value:string,subServiceId:number) => {
    this.setState((prevState) => ({
      selectedIds: 0,
      recurrenceStates: {
        ...prevState.recurrenceStates,
        [subServiceId]: value,
      },
    }));
    this.props.setSubService(null)
    this.getButtonClass(subServiceId);
    this.getButtonName(subServiceId);
  };

  getButtonClass = (id:number) => {
    const { recurrenceStates, selectedIds } = this.state;
    const { serviceType } = this.props;
    const recurrenceServiceList = [configJSON.SERVICES_ENUM.DAY_CARE, configJSON.SERVICES_ENUM.DOG_WALKING];
    const isRecurrenceShow = recurrenceServiceList.includes(serviceType);
    const isRecurrenceSelected = recurrenceStates[id] && recurrenceStates[id] !== '';
  
    let additionalClass = '';
  
    switch (true) {
      case selectedIds === id:
        additionalClass = 'select-btn'; 
        break;

      case !isRecurrenceShow:
        additionalClass = 'purple-btn'; 
        break;
  
      case isRecurrenceShow && isRecurrenceSelected:
      case isRecurrenceShow && !isRecurrenceSelected:
        additionalClass = 'purple-btn';
        break;
        
      default:
        additionalClass = 'notSelectBtn'; 
    }
  
    return `table-btn ${additionalClass}`;
  };

  handleGalleyShow = (capacity: string, subServicePrice: string, description: string, subServiceId: number) => {
    this.setState({ isSubServiceGalleryOpen: true, subServiceGalleryData: { capacity: capacity, price: subServicePrice, description: description } });
    this.getSubServiceGalleryAPICall(subServiceId);
  }

  handleCloseViewMore = () => {
    this.setState({ isSubServiceGalleryOpen: false, subServiceGalleryData: { capacity: "", price: "", description: "" } })
  }

  apiCall = async (data: IApiModel) => {
    const {
      contentType,
      method,
      endPoint,
      body,
    } = data;
    const header = {
      "Content-Type": contentType,
    };

    const requestedMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestedMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestedMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestedMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestedMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestedMessage.id, requestedMessage);
    return requestedMessage.messageId;
  };

  getSubServiceGalleryAPICall = async (subServiceId: number) => {
    this.setState({ isGalleryLoading: true });
    this.getSubServiceGalleryApiId = await this.apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.ENDPOINTS.GET_SUB_SERVICE_GALLERY}${subServiceId}`,
    });
  };

  handleSubServiceResponse = (responseJson: ISubServiceResponse) => {
    this.setState({ isGalleryLoading: false })
    if (responseJson.galleries && responseJson.galleries.length) {
      this.setState({ subServiceImageList: responseJson.galleries });
    }
  }

  transTable = (tableKey: string) => {
    return i18n.t(tableKey, { ns: "scheduling" })
  }
  // Customizable Area End
}