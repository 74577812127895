export const enLandingPage = {
    "Recently Booked":"Recently Booked",
    "Find the service":"Find the service",
    "Register your service to Petzai and join the future of the pet community":"Register your service to Petzai and join the future of the pet community",
    "It takes two minutes for your registration to be complete, so why wait? Start sharing and selling your services with the world today!":"It takes two minutes for your registration to be complete, so why wait? Start sharing and selling your services with the world today!",
    //HEADER
    "Welcome to the":"Welcome to the",
    "home of":"home of",
    "Pet hotels.":"Pet hotels.",
    "Dog walkers.":"Dog walkers.",
    "Pet sitters.":"Pet sitters.",
    "Day care.":"Day care.",
    "Trainers.":"Trainers.",
    "HOTELS":"HOTELS",
    "DOG WALKING":"DOG WALKING",
    "GROOMING":"GROOMING",
    "PET SITTING":"PET SITTING",
    "DAY CARE":"DAY CARE",
    "TRAINING":"TRAINING",
    "Country":"Country",
    "City":"City",
    "Start Date":"Start Date",
    "End Date":"End Date",
    "Your Pet":"Your Pet",
    "How often do you need this service?":"How often do you need this service?",
    "Choose Repeat Weekly for recurring booking":"Choose Repeat Weekly for recurring booking",
    "Repeat Weekly":"Repeat Weekly",
    "Days of the week":"Days of the week",
    "End After":"End After",
    //PLACEHOLDER
    "Choose a Date":"Choose a Date",
    "Select animal":"Select animal",
    "Select country":"Select country",
    "Select city":"Select city",
    //PETS
    "Dog":"Dog",
    "Cat":"Cat",
    "Other":"Other",
    "Rabbit/Rodent":"Rabbit/Rodent",
    "Reptile":"Reptile",
    "Bird":"Bird",
    "Fish":"Fish",
    //BUTTON
    "Search":"Search",
    "Get started":"Get started",
    //MVP
    "Soon":"Soon"
}

export const ptLandingPage = {
    "Recently Booked":"Reservado recentemente",
    "Find the service":"Encontre o serviço",
    "Register your service to Petzai and join the future of the pet community":"Registre seu serviço na Petzai e junte-se ao futuro da comunidade pet",
    "It takes two minutes for your registration to be complete, so why wait? Start sharing and selling your services with the world today!":"O seu registro leva dois minutos para ser concluído, então por que esperar? Comece a compartilhar e vender seus serviços com o mundo hoje!",
    //HEADER
    "Welcome to the":"Bem-vindo ao",
    "home of":"casa de",
    "Pet hotels.":"Hotéis para animais de estimação.",
    "Dog walkers.":"Passeadores de cães.",
    "Pet sitters.":"Babás de animais de estimação.",
    "Day care.":"Creche.",
    "Trainers.":"Formadoras.",
    "Grooming.": "Asseio",
    "Training.": "Treinamento",
    "HOTELS":"HOTÉIS",
    "DOG WALKING":"PASSEIO DE CÃO",
    "GROOMING":"ASSEIO",
    "PET SITTING":"PET SITTING",
    "DAY CARE":"CUIDADO DIÁRIO",
    "TRAINING":"TREINAMENTO",
    "Country":"País",
    "City":"Cidade",
    "Start Date":"Data de início",
    "End Date":"Data de término",
    "Your Pet":"Seu animal de estimação",
    "How often do you need this service?":"Com que frequência você precisa deste serviço?",
    "Choose Repeat Weekly for recurring booking":"Escolha Repetir semanalmente para reservas recorrentes",
    "Repeat Weekly":"Repita semanalmente",
    "Days of the week":"Dias da semana",
    "End After":"Terminar depois",
    //PLACEHOLDER
    "Choose a Date":"Escolha uma data",
    "Select animal":"Selecione o animal",
    "Select country":"Selecione o país",
    "Select city":"Selecione a cidade",
    //PETS
    "Dog":"Cachorro",
    "Cat":"Gato",
    "Other":"Outro",
    "Rabbit/Rodent":"Coelho/Roedor",
    "Reptile":"Réptil",
    "Bird":"Pássaro",
    "Fish":"Peixe",
    //BUTTON
    "Search":"Procurar",
    "Get started":"Comece",
    //MVP
    "Soon":"Breve"
}