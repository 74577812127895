import React from "react";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { Avatar, Box, Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { AppFooter } from "../../../components/src/AppFooter/appFooter";
import { LandingPageHeader } from "../../../components/src/LandingPageHeaderWeb/LandingPageHeader.web";
import {
  carouselNextArrowIcon,
  carouselPrevArrowIcon,
  LandingPageFooterImg,
  LocationIcon,
  NextArrowIcon,
} from "./assets";
import Slider, { Settings } from "react-slick";
import { TypeAnimation } from "react-type-animation";
const configJSON = require("./config");
import { SimpleDialog } from "../../../components/src/HelperComponents";
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

// Customizable Area Start
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
});

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ width: "100%" }}>{children}</Box>}
    </div>
  );
}

const LandingPageContainer = styled(Box)(({ theme }) => ({
  "& .footer-container": {
    position: "relative",
    width: "100%",
    height: "550px",
    overflow: "hidden",
    marginTop:"50px"
  },
  "& .footer-img": {
    height: "100%",
    width: "100%",
    objectFit:"cover"
  },
  "& .footer-content": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
    textAlign: "center",
  },
  "& .footer-text-part-a": {
    fontWeight: "700",
    fontSize: "34px",
    lineHeight: "1.2",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
  },
  "& .footer-text-part-b": {
    fontWeight: "400",
    fontSize: "18px",
    textAlign: "center",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  "& .footer-btn": {
    border: "1px solid #fff",
    borderRadius: "100px",
    padding: "5px 45px",
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "none",
    color: "#6F05EA",
    backgroundColor: "#fff",
    boxShadow: "none",
    marginTop: "20px",
    "&:hover": {
      color: "#6F05EA",
      backgroundColor: "#fff",
      boxShadow: "none",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "5px 35px",
      fontSize: "14px",
    },
  },
  "& .progressBarContainer":{
    display:"flex",
    justifyContent:"center",
    "& .MuiCircularProgress-colorPrimary":{
      color:"#6F05EA"
    }
  }
}));
const TabPanelContainer = styled(Box)(({ theme }) => ({
  padding: "0px 160px",
  [theme.breakpoints.down("md")]: {
    padding: "0px 100px",
  },
  [theme.breakpoints.down(760)]: {
    padding: "0px 40px",
  },
  [theme.breakpoints.down("xs")]: {
    padding: "0px 40px",
  },
}));
const SliderContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    marginTop:"-80px"
  },
  "& .recently-book-title": {
    fontWeight: 600,
    fontSize: "24px",
    marginBottom: "30px",
  },

  "& .card-location-info": {
    display: "flex",
    gap: "5px",
  },
  "& .card-location-icon":{
    height:"14px",
    width:"14px"
  },
  "& .card-container": {
    overflow: "hidden",
    borderRadius: "25px",
    boxShadow: "0px 0px 5px rgba(0,0,0,25%)",
    maxWidth:"345px",
    height:"425px",
    width:"100%",
  },
  "& .card-img": {
    width: "100%",
    height: "191px",
    maxWidth:"345px",
    objectFit:"cover"
  },
  "& .card-img-no-found": {
    width: "100%",
    height: "191px",
    maxWidth:"345px",
    display:"flex",
    justifyContent:"center", 
    alignItems:"center",
    backgroundColor:"#A6A6A62B",
    fontWeight:500,
    fontSize:"34px",
    textTransform:"capitalize",
    color:"#A6A6A6",
    borderRadius:"0px !important",
    fontFamily:"Montserrat",
  },
  "& .card-data-container": {
    padding: "16px 16px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  "& .card-price": {
    color: "#6F05EA",
    fontWeight: 800,
    fontSize: "16px",
    height:"25px"
  },
  "& .card-price-span": {
    color: "#6F05EA",
    fontWeight: 400,
    height:"25px"
  },
  "& .card-title": {
    fontSize: "16px",
    fontWeight: 600,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    MozBoxOrient: "vertical",
    height: "48px",
  },
  "& .card-location": {
    fontSize: "12px",
    color: "#A6A6A6",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    MozBoxOrient: "vertical",
    height: "36px",
  },
  "& .book-btn-container": {
    display: "flex",
    marginTop: "10px",
    justifyContent: "center",
    height:"44px",
  },
  "& .book-btn": {
    border: "1px solid #6F05EA",
    borderRadius: "100px",
    padding: "5px 45px",
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "none",
    color: "#fff",
    backgroundColor: "#6F05EA",
    boxShadow: "none",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#6F05EA",
      boxShadow: "none",
    },
    [theme.breakpoints.down("md")]: {
      padding: "5px 35px",
      fontSize: "14px",
    },
  },
  "& .no-data-found":{
    color:"rgb(183, 183, 183)",
    textAlign:"center",
    fontWeight:600,
    fontSize:22
  },
}));
const FindServiceSliderContainer = styled(Box)(({theme}) => ({
  marginTop:"60px",
"& .find-service-title":{
  fontSize: "24px",
  fontWeight: 600,
  marginBottom: "30px",
},
  "& .slick-prev": {
    height:"50px",
    zIndex: 10,
    // left: "0",
    [theme.breakpoints.down("xs")]: {
      height:"20px"
    },
  },
  "& .slick-next": {
    height:"50px",
    // right: "0",
    [theme.breakpoints.down("xs")]: {
      height:"20px"
    },
  },
  "& .slick-slider": {
    height: "233px",
  },
  "& .slick-list":{
    maxWidth:"1180px",
    margin:"auto"
  },
  "& .find-service-container":{
    flexDirection: "column", 
    alignItems:"center",
    gap:"15px", 
    display: "flex", 
    height:"100%",
    maxWidth:"185px",
    cursor:"pointer"
  },
  "& .find-service-img":{
    height:"180px", 
    borderRadius:"50%",
    width:"180px", 
  },
  "& .find-service-card-title":{
    height: "48px",
    fontWeight:"700", 
    color:"#6F05EA", 
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign:"center",
    WebkitLineClamp: 2,
    MozBoxOrient: "vertical",
  },
  "& .find-service-box":{
    height:"100%",
    display:"flex !important",
    justifyContent:"center !important",
  },
  "& .no-data-found":{
    textAlign:"center",
    fontWeight:600,
    color:"rgb(183, 183, 183)",
    fontSize:22
  },
}))
// Customizable Area End

export default class LandingPageWeb extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderHotelTabPanel = () => {
    return (
      <TabPanelContainer data-test-id="tab-panel-container">
        {this.renderRecentlyFindService()}
      </TabPanelContainer>
    );
  };
  
  renderFooterContainer = () => {
    return (
      <>
        <Box className="footer-container">
          <img
            src={LandingPageFooterImg}
            alt="landing-page-banner"
            className="footer-img"
          />
          <Box className="footer-content">
            <Typography className="footer-text-part-a">
              {this.transLandingPage("Register your service to Petzai and join the future of the pet community")}
            </Typography>
            <Typography className="footer-text-part-b">
              {this.transLandingPage("It takes two minutes for your registration to be complete, so why wait? Start sharing and selling your services with the world today!")}
            </Typography>
            <Button
              onClick={this.handleGetStartClick}
              className="footer-btn"
              endIcon={<img src={NextArrowIcon} alt="next-arrow" />}
              data-test-id="get-start-btn"
            >
              {this.transLandingPage("Get started")}
            </Button>
          </Box>
        </Box>
      </>
    );
  };

  renderRecentlyBookedService = () => {
    const arrayData = this.state.recentlyBookedList.slice(0,3);
    return (
      <SliderContainer>
        <Typography className="recently-book-title">{this.transLandingPage("Recently Booked")}</Typography>
          {this.state.recentlyBookedList.length > 0 &&
          !this.state.getRecentlyBookedLoader ? (
            <StyledRecentlyBox container>
                {arrayData.map((card) => (
                <Grid item sm={4} key={card.sub_service_id} style={{maxWidth:"343px"}}>
                  <Box className="card-container">
                    {card.sub_service_image === "Image not found" && <Avatar className="card-img-no-found">{card.establishment_name.charAt(0)}</Avatar>}
                    {card.sub_service_image !== "Image not found" && <img
                      src={card.sub_service_image}
                      className="card-img"
                    />}
                    <Box className="card-data-container">
                      <Typography className="card-title">
                        {card.establishment_name}
                      </Typography>
                      <Box className="card-location-info">
                        <img
                          src={LocationIcon}
                          alt="location-icon"
                          className="card-location-icon"
                        />
                        <Typography className="card-location">
                          {card.establishment_address}
                        </Typography>
                      </Box>
                      <Typography className="card-price">
                        from €{card.sub_services_price}
                        <span className="card-price-span">/night</span>
                      </Typography>
                      <Box className="book-btn-container">
                        <Button
                          onClick={() => this.goTOBookingPage(card)}
                          className="book-btn"
                          style={{ backgroundColor: "#8000ff" }}
                          data-test-id="book-btn"
                        >
                          Book
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
              </StyledRecentlyBox>
          ) : (
            <></>
          )}
        {!this.state.recentlyBookedList.length &&
        this.state.getRecentlyBookedLoader ? (
          <Box
            className="progressBarContainer"
            style={{ height: "430px", alignItems: "center" }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <></>
        )}
        {!this.state.recentlyBookedList.length &&
        !this.state.getRecentlyBookedLoader ? (
          <Typography className="no-data-found">No results found!</Typography>
        ) : (
          <></>
        )}
      </SliderContainer>
    );
  };

  renderFindServiceContent = (setting:Settings) => {
    const findServiceDataList = this.state.findServiceDataList?.data;
    return (
      <FindServiceSliderContainer data-test-id="find-service-slider">
        <Typography className="find-service-title">{this.transLandingPage("Find the service")}</Typography>
        {!findServiceDataList?.length &&
          this.state.getFindServiceLoader ? (
          <Box className="progressBarContainer">
            <CircularProgress />
          </Box>
        ) : (
          <></>
        )}
        {!findServiceDataList?.length &&
          !this.state.getFindServiceLoader ? (
          <Typography className="no-data-found">No results found!</Typography>
        ) : (
          <></>
        )}
        {findServiceDataList?.length > 0 && !this.state.getFindServiceLoader ? (
          <Slider {...setting} data-test-id="find-service-container">
            {findServiceDataList?.map((card, index: number) => {
              const cardImg = card.attributes?.find_service_photo?.url;
              const serviceTitle = card.attributes?.service_title;
              const serviceType = card.attributes?.service_type;
              return (
                <Box
                  key={card.id}
                  data-test-id="find-service"
                  className="find-service-box"
                >
                  <Box className="find-service-container" data-test-id={`find-service-box-${serviceType}`} onClick={() => this.handleFindServiceClick(serviceType)}>
                    <Avatar
                      src={cardImg}
                      alt={`card-image-${index}`}
                      className="find-service-img"
                    >
                      {serviceTitle.charAt(0)}
                    </Avatar>
                    <Typography className="find-service-card-title">
                      {serviceTitle}
                    </Typography>
                  </Box>
                </Box>
              )
            })}
          </Slider>) : (<></>)}
      </FindServiceSliderContainer>
    );
  }

  renderFindService = () => {
    const findServiceDataList = this.state.findServiceDataList?.data;
    
    const setting = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      nextArrow: findServiceDataList?.length > 5 ? <img src={carouselNextArrowIcon} alt="carousel-next-arrow" /> : null,
      prevArrow: findServiceDataList?.length > 5 ? <img src={carouselPrevArrowIcon} alt="carousel-next-arrow" /> : null,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            nextArrow: findServiceDataList?.length > 4 ? <img src={carouselNextArrowIcon} alt="carousel-next-arrow" /> : null,
            prevArrow: findServiceDataList?.length > 4? <img src={carouselPrevArrowIcon} alt="carousel-next-arrow" /> : null,
          },
        },
        {
          breakpoint: 990,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            nextArrow: findServiceDataList?.length > 3 ? <img src={carouselNextArrowIcon} alt="carousel-next-arrow" /> : null,
            prevArrow: findServiceDataList?.length > 3 ? <img src={carouselPrevArrowIcon} alt="carousel-next-arrow" /> : null,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            nextArrow: findServiceDataList?.length > 2 ? <img src={carouselNextArrowIcon} alt="carousel-next-arrow" /> : null,
            prevArrow: findServiceDataList?.length > 2 ? <img src={carouselPrevArrowIcon} alt="carousel-next-arrow" /> : null,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: findServiceDataList?.length > 1 ? <img src={carouselNextArrowIcon} alt="carousel-next-arrow" /> : null,
            prevArrow: findServiceDataList?.length > 1 ? <img src={carouselPrevArrowIcon} alt="carousel-next-arrow" /> : null,
          },
        },
      ],
    };
    
    return(
      this.renderFindServiceContent(setting)
    )
  };

  renderRecentlyFindService = () => {
    return(
      <>
      {this.renderRecentlyBookedService()}
      {this.renderFindService()}
      </>
    )
  }
  renderAnimatedTitle = () => {
    const LanguageAnimation = configJSON.DEFAULT_MSG.ANIMATION_ARRAY.map((item:string , index:number) => {
      if (index % 2 === 0) {
        return this.transLandingPage(item);
      }
      return item;
    });
    
    return (
      <>
        <TitleBox data-testid="title-wrapper">
          <TitleText data-testid="title-testid">{this.transLandingPage(configJSON.DEFAULT_MSG.PAGE_TITLE)}</TitleText>
          <TitleText>{this.transLandingPage("home of")} {" "}
            <span>
              <TypeAnimation
                sequence={LanguageAnimation}
                repeat={Infinity}
                style={defaultStyle.animatedText}
              />
            </span>
          </TitleText>
        </TitleBox>
      </>
    )
  }

  renderLogOutDialog = () => {
    const { isLogOutOpen } = this.state
    return (
      <SimpleDialog
        data-test-id="logout-dialog"
        open={isLogOutOpen}
        message={configJSON.SIMPLE_DIALOG.MESSAGE}
        showRightBtn={true}
        showLeftBtn={true}
        leftBtnTitle={configJSON.SIMPLE_DIALOG.CONFIRM_MESSAGE}
        rightBtnTitle={configJSON.SIMPLE_DIALOG.CANCEL_MESSAGE}
        handleRightClick={this.handleCancelLogout}
        handleLeftClick={this.handleSureLogout}
      />
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const isRecurrenceShow = [1,3,4].includes(this.state.tabValue);

    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
        <ThemeProvider theme={theme}>
          <style>
        {`
          .MuiButton-endIcon{
            margin-left: 20px
          }
        `}
      </style>
        <AppHeader navigation={this.props.navigation}/>
        <LandingPageContainer data-test-id="landing-page-container">
          {this.renderAnimatedTitle()}
          <LandingPageHeader
            tabValue={this.state.tabValue}
            onTabChange={this.handleTabChange}
            SearchDataFromHeader={(data) => this.goToAdvancedSearchPage(data)}
            data-test-id="landing-page-header"
            isRepeatShow={isRecurrenceShow}
            petList={this.state.petsList}
            hideTitle
          />
          {this.renderHotelTabPanel()}
          {this.renderFooterContainer()}
          {this.renderLogOutDialog()}
        </LandingPageContainer>
        <AppFooter navigation={this.props.navigation} />
        </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

const TitleBox = styled(Box)(({ theme }) => ({
  background: "linear-gradient(to right, #6928F2, #884FFF)",
  padding: "60px 152px",
  [theme.breakpoints.down("lg")]: {
    padding: "60px 100px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "60px 85px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "30px 60px",
  },
  [theme.breakpoints.down("xs")]: {
    padding: "20px 40px",
  },
}));
const TitleText = styled(Typography)(({ theme }) => ({
  userSelect: "none",
  fontFamily: "Montserrat",
  fontWeight: 400,
  lineHeight: "80.39px",
  textAlign:"left",
  color: "#FFFFFF",
  fontSize: "60px",
  [theme.breakpoints.down("lg")]: {
    fontSize: "56px",
    lineHeight: "70.39px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "48px",
    lineHeight: "60.39px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "32px",
    lineHeight: "40.39px",
  },
  "@media (max-width: 450px)": {
    fontSize: "24px",
    lineHeight: "30.39px",
  },
  "& span":{
    fontWeight: 600,
    textTransform: "lowercase",
    [theme.breakpoints.down("lg")]: {
      fontSize: "56px",
      lineHeight: "70.39px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "48px",
      lineHeight: "60.39px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "32px",
      lineHeight: "40.39px",
    },
    "@media (max-width: 450px)": {
      fontSize: "24px",
      lineHeight: "30.39px",
    },
  }
}));
const defaultStyle = {
  animatedText: {
    background: 'linear-gradient(91.85deg, #FFB800 7.52%, #FDD773 73.79%, #FDE3A1 100.24%)',
    WebkitBackgroundClip: 'text', 
    WebkitTextFillColor: 'transparent'
  } as React.CSSProperties
}

const StyledRecentlyBox = styled(Grid)({
  display: "flex", 
  justifyContent: "center",
  gap:"50px",
  flexWrap:"nowrap",
  "@media (max-width: 1480px)": {
    margin:"0 20px"
  },
  "@media (max-width: 1080px)": {
    gap:"20px",
  },
  "@media (max-width: 700px)": {
    flexDirection:"column",
    alignItems:"center",
    margin:"0px"
  },
})
// Customizable Area End
