import React, { Component } from 'react';
import { Chart, DoughnutController, ArcElement, Tooltip, Legend } from 'chart.js';

Chart.register(DoughnutController, ArcElement, Tooltip, Legend);

export default class DoughnutChart extends Component {
  chartRef = React.createRef<HTMLCanvasElement>(); // Create a reference for the canvas element

  componentDidMount() {
    const ctx = this.chartRef.current?.getContext('2d');
    if (!ctx) return;

    // Define the data and options for the chart
    const data = {
      labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple',],
      datasets: [
        {
          label: 'Votes',
          data: [20, 20, 20, 30, 10],
          backgroundColor: [
            '#F59E0B',
            '#60A5FA',
            '#EC4899',
            '#6200EA',
            '#2563EB',

          ],
          borderColor: [
            '#FFF',
            '#FFF',
            '#FFF',
            '#FFF',
            '#FFF'
          ],
          borderWidth: 1,
        },
      ],
    };

    const options = {
      responsive: false, // Disable responsiveness to enforce fixed size
      plugins: {
        legend: {
          display: false, // Hide the legend
        },
        tooltip: {
          enabled: false, // Hide tooltips (optional)
        },
      },
      cutout: '70%',
    };

    // Initialize the chart
    new Chart(ctx, {
      type: 'doughnut',
      data,
      options,
    });
  }

  render() {
    return (
      <div style={{ width: '160px', height: '160px' }}>
        <canvas
          ref={this.chartRef}
          width="160"
          height="160"
          style={{ display: 'block' }}
        ></canvas>
      </div>
    );
  }
}
